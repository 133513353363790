export const ignoreErrors = [
  "<anonymous>",
  "TypeError: Failed to fetch",
  "GraphQLError: Unauthenticated",
  "TypeError: undefined is not an object (evaluating 'n.length')",
  "FirebaseError: Firebase: Error (auth/invalid-custom-token).",
  "FirebaseError: Firebase: Error (auth/network-request-failed).",
  "RelayNetwork: No data returned for operation `enhancedUserTrackingDasboardQuery`, got error(s):",
  "PrismaClientKnownRequestError:",
  "GraphQLError: You don't have permission to do that.",
  "TypeError: Cannot read properties of undefined (reading 'bind')",
  "GraphQLError: You don't have access to do that.",
  /Non-Error promise rejection captured with value: /,
  /^Non-Error promise rejection captured.*Object Not Found Matching Id$/,
]
