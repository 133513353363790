import { Link } from "@tanstack/react-router"
import clsx from "clsx"

import { GlowIcon, GlowIconName, GlowText } from "src/glow"

type ReferFriendsButtonProps = {
  iconName: GlowIconName
  label: string
  onClick?: () => void
  className?: string
}

const ReferFriendsButton = ({
  iconName,
  label,
  onClick,
  className,
}: ReferFriendsButtonProps) => {
  return (
    <Link
      to="/referrals"
      className={clsx(
        "bg-virtuous-green-100 text-virtuous-green-500 relative flex items-center justify-center gap-2 truncate rounded-full px-2 py-1 hover:underline",
        className,
      )}
      onClick={onClick}
    >
      <GlowIcon name={iconName} className="shrink-0" />
      <GlowText size="sm" fontWeight="medium" className="truncate">
        {label}
      </GlowText>

      <div className="z-sticky-banner animate-shimmer shimmer-gradient absolute left-0 top-0 h-full w-full bg-[size:300%] bg-right"></div>
    </Link>
  )
}

export default ReferFriendsButton
