import { useCallback, useRef } from "react"

import { Options } from "@segment/analytics-next"

import { useUser } from "src/auth"
import useEffectAfterRouterTransition from "src/hooks/useEffectAfterRouterTransition"

import { buildIntegrations } from "./buildIntegrations"
import { useAnalytics } from "./useAnalytics"
import { obfuscateUrl } from "./utils"

export type PageCategory =
  | "Task"
  | "Statement"
  | "Login"
  | "Token Login"
  | "Forgot password"
  | "Reset password"
  | "Onboarding"
  | "Account creation"
  | "Bank connection"
  | "Home"
  | "Home category details"
  | "Your household"
  | "Mortgage calculator"
  | "Mortgage LTV calculator"
  | "Inflationary pay rise calculator"
  | "Mobile price rise calculator"
  | "Budget 2023 calculator"
  | "Budget 2024 calculator"
  | "Energy forecast"
  | "Deal Card"
  | "Recommendation"
  | "Planner"
  | "Tools"
  | "Widgets"
  | "Savings"
  | "Preferences wizard"
  | "Documents"
  | "Induction"
  | "Mortgage wizard"
  | "Energy wizard"
  | "Broadband wizard"
  | "Mobile wizard"
  | "Bank details wizard"
  | "Claim household"
  | "Induction"
  | "Savings Plan"
  | "Activate Broadband"
  | "Activate Energy"
  | "Activate Mobile"
  | "Activate Mortgage"
  | "Activate Service"
  | "Tasks"
  | "Notifications"
  | "Link Bank Details"
  | "Event information"
  | "Document"
  | "Savings Report"
  | "Nous Premium Welcome"
  | "Service level"
  | "Waitlist"
  | "Onboarding"
  | "Bank details"
  | "WhatsApp details"
  | "Quests"
  | "Quest"
  | "Connect Email"
  | "Connect WhatsApp"
  | "Settings"
  | "Referrals"

const consolePage = (
  category: string,
  pathname: string,
  properties?: Options,
) => {
  type Args = { category: string; pathname: string; properties?: Options }
  const args: Args = { category, pathname }

  if (properties !== undefined) {
    args.properties = properties
  }

  // eslint-disable-next-line no-console
  console.debug("%cPAGE", "font-weight: bold; color: darkMagenta", args)
}

export default function usePageTrack(
  pageCategory: PageCategory,
  options: {
    pathnameOverride?: string
    properties?: Options
  } = {},
) {
  const analytics = useAnalytics()
  const calledRef = useRef(false)
  const user = useUser()

  useEffectAfterRouterTransition(
    useCallback(
      (pathname) => {
        if (calledRef.current) {
          return
        }
        calledRef.current = true
        const analyticsPathname = options.pathnameOverride ?? pathname

        const params = {
          url: obfuscateUrl(window.location.href),
          search: "",
          ...options.properties,
          ...(user?.email && { email: user.email }),
        }

        analytics?.page(pageCategory, analyticsPathname, params, {
          integrations: buildIntegrations(),
        })
        consolePage(pageCategory, analyticsPathname, params)
      },
      [
        analytics,
        pageCategory,
        user?.email,
        options.pathnameOverride,
        options.properties,
      ],
    ),
  )
}
