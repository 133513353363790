import { FormattedMessage } from "react-intl"
import { graphql, useFragment } from "react-relay"

import { GlowFlexbox, GlowHorizontalDivider, GlowText } from "src/glow"
import { DeleteMyAccount } from "src/pages/SettingsPage/components/DeleteMyAccount/DeleteMyAccount"
import { Logout } from "src/pages/SettingsPage/components/Logout/Logout"

import { SettingsPageOverview_household$key } from "./__generated__/SettingsPageOverview_household.graphql"
import { SettingsPageOverview_user$key } from "./__generated__/SettingsPageOverview_user.graphql"
import { SettingsPageOverviewBankAccounts } from "./components/SettingsPageOverviewBankAccounts"
import { SettingsPageOverviewHouse } from "./components/SettingsPageOverviewHouse"
import { SettingsPageOverviewInboxes } from "./components/SettingsPageOverviewInboxes"
import { SettingsPageOverviewMembers } from "./components/SettingsPageOverviewMembers"
import { SettingsPageOverviewMembership } from "./components/SettingsPageOverviewMembership"
import { SettingsPageOverviewNotifications } from "./components/SettingsPageOverviewNotifications"
import { SettingsPageOverviewSavingsMode } from "./components/SettingsPageOverviewSavingsMode"
import { SettingsPageOverviewShareInvite } from "./components/SettingsPageOverviewShareInvite"

export const SettingsPageOverview = (props: {
  household: SettingsPageOverview_household$key
  user?: SettingsPageOverview_user$key | null
  onGoToAccountView: () => void
  onGoToMembershipView: () => void
  onGoToSavingsModeView: () => void
  onGoToMembersView: () => void
  onGoToBankAccountsView: () => void
  onGoToInboxesView: () => void
  onGoToNotificationsView: () => void
}) => {
  const household = useFragment(
    graphql`
      fragment SettingsPageOverview_household on Household {
        ...SettingsPageOverviewHouse_household
        ...SettingsPageOverviewShareInvite_household
        ...SettingsPageOverviewMembership_household
        ...SettingsPageOverviewSavingsMode_household
        ...SettingsPageOverviewMembers_household
        ...SettingsPageOverviewBankAccounts_household
        ...SettingsPageOverviewInboxes_household
        id
      }
    `,
    props.household,
  )

  const user = useFragment(
    graphql`
      fragment SettingsPageOverview_user on User {
        ...DeleteMyAccount_user
      }
    `,
    props.user,
  )

  return (
    <GlowFlexbox direction="column" gap="4">
      <SettingsPageOverviewHouse
        household={household}
        onGoToAccountView={props.onGoToAccountView}
      />
      <SettingsPageOverviewShareInvite household={household} />
      <SettingsPageOverviewMembership
        household={household}
        onGoToMembershipView={props.onGoToMembershipView}
      />
      <SettingsPageOverviewSavingsMode
        household={household}
        onGoToSavingsModeView={props.onGoToSavingsModeView}
      />
      <SettingsPageOverviewMembers
        household={household}
        onGoToMembersView={props.onGoToMembersView}
      />
      <SettingsPageOverviewBankAccounts
        household={household}
        onGoToBankAccountsView={props.onGoToBankAccountsView}
      />
      <SettingsPageOverviewInboxes
        household={household}
        onGoToInboxesView={props.onGoToInboxesView}
      />
      <SettingsPageOverviewNotifications
        onGoToNotificationsView={props.onGoToNotificationsView}
      />

      <GlowHorizontalDivider />

      <Logout />

      <DeleteMyAccount user={user} />

      <GlowText className="text-gray-500/64 text-center text-sm">
        <FormattedMessage
          id="page.settings.householdId"
          defaultMessage="NOUS-{nanoHouseholdId}"
          values={{
            householdId: household.id,
            nanoHouseholdId: household.id.slice(-5),
          }}
        />
      </GlowText>
    </GlowFlexbox>
  )
}
