import React from "react"

import { Link, LinkProps } from "@tanstack/react-router"
import clsx from "clsx"

import { GlowIcon, GlowIconName } from "./GlowIcon"
import { GlowLoadingDots } from "./GlowLoadingDots"
import { GlowResponsiveSpacing, glowSpacingToClassNames } from "./structure"

type GlowButtonVariant =
  | "primary"
  | "primary-yellow"
  | "primary-green"
  | "primary-red"
  | "secondary"
  | "tertiary"
  | "gradient"

type GlowButtonSize = "base" | "sm" | "xs"

type GlowButtonIconPosition = "left" | "right"

export type GlowButtonCommonProps = {
  label: string
  size?: GlowButtonSize
  icon?: React.ReactElement
  iconName?: GlowIconName
  iconClassName?: string
  iconPosition?: GlowButtonIconPosition
  variant?: GlowButtonVariant
  isDisabled?: boolean
  margin?: GlowResponsiveSpacing
  padding?: GlowResponsiveSpacing
  isLoading?: boolean
}

type Props = GlowButtonCommonProps &
  (
    | ({ href: string; to?: undefined } & React.ComponentProps<"a">)
    | ({ href?: undefined; to: string; className?: string } & Omit<
        LinkProps,
        "to"
      >)
    | ({ href?: undefined; to?: undefined } & Omit<
        React.ComponentProps<"button">,
        "disabled"
      >)
  )

const variantStyleRecord: Record<GlowButtonVariant, string> = {
  primary:
    "px-3.5 rounded-full " +
    "bg-gray-500 text-white border-gray-500 border-2 outline-offset-0 ",
  "primary-yellow":
    "px-3.5 rounded-full " +
    "bg-nous-glow-400 text-gray-500 border-nous-glow-400 border-2 outline-offset-0 ",
  "primary-green":
    "px-3.5 rounded-full " +
    "bg-virtuous-green-400 text-gray-500 border-virtuous-green-400 border-2 outline-offset-0 ",
  "primary-red":
    "px-3.5 rounded-full " +
    "bg-angsty-red-400 text-white border-angsty-red-400 border-2 outline-offset-0 ",
  secondary:
    "px-3.5 rounded-full " +
    "bg-white text-gray-500 border-2 border-gray-500 outline-offset-0 ",
  tertiary:
    "px-[15px] rounded-full " +
    "bg-transparent text-gray-400 border border-gray-500/20 outline-offset-0 ",
  gradient:
    "px-3.5 rounded-full radial-gradient text-off-black-64 outline-offset-0 font-medium text-sm ",
}
const variantHoverAndFocusStyleRecord: Record<GlowButtonVariant, string> = {
  primary:
    "hover:bg-white hover:text-gray-500 transition-all duration-300 linear" +
    "focus:outline-2 focus:outline-serene-blue-400 ",
  "primary-yellow":
    "hover:bg-nous-glow-300 " +
    "focus:outline-2 focus:outline-serene-blue-400 ",
  "primary-green":
    "hover:bg-virtuous-green-300 " +
    "focus:outline-2 focus:outline-serene-blue-400 ",
  "primary-red":
    "hover:bg-angsty-red-300 " +
    "focus:outline-2 focus:outline-serene-blue-400 ",
  secondary:
    "hover:bg-gray-500 hover:text-white " +
    "focus:outline-2 focus:outline-serene-blue-400 focus:border-transparent ",
  tertiary:
    "hover:underline hover:border-gray-500/60 " +
    "focus:underline focus:border-transparent focus:outline-2 focus:outline-serene-blue-400",
  gradient:
    "hover:radial-gradient-inverted hover:text-gray-500 " +
    "focus:outline-2 focus:outline-serene-blue-400 ",
}

function resolveSize(size: GlowButtonSize, variant: GlowButtonVariant) {
  switch (size) {
    case "base":
      return variant === "tertiary"
        ? "py-[11px]"
        : variant === "gradient"
          ? "py-[14px]"
          : "py-[10px]"
    case "sm":
      return variant === "tertiary"
        ? "py-[7px]"
        : variant === "gradient"
          ? "py-[10px]"
          : "py-[6px]"
    case "xs":
      return variant === "tertiary"
        ? "py-[5px]"
        : variant === "gradient"
          ? "py-[8px]"
          : "py-[4px]"
  }
}

export function GlowButton({
  isLoading,
  variant = "primary",
  icon,
  iconName,
  iconClassName,
  iconPosition = "left",
  size = "base",
  isDisabled,
  ...props
}: Props) {
  const className = clsx([
    "group",
    "flex gap-2 items-center justify-center",
    "transition-colors duration-75",
    variantStyleRecord[variant],
    isDisabled || variantHoverAndFocusStyleRecord[variant],
    isDisabled && "opacity-30 cursor-not-allowed",
    props.className,
    resolveSize(size, variant),
    ...glowSpacingToClassNames(props.padding ?? {}, "p"),
    ...glowSpacingToClassNames(props.margin ?? {}, "m"),
  ])
  const resolvedIcon = icon ?? (iconName && <GlowIcon name={iconName} />)
  const transformedIcon =
    resolvedIcon &&
    React.cloneElement(resolvedIcon, {
      className: clsx([
        resolvedIcon.props.className,
        iconClassName || "w-6 h-6 shrink-0",
      ]),
    })

  if (props.to) {
    return (
      <Link {...props} className={className}>
        {iconPosition == "left" && transformedIcon}
        {isLoading ? <GlowLoadingDots /> : props.label}
        {iconPosition == "right" && transformedIcon}
      </Link>
    )
  }

  if (props.href !== undefined) {
    return (
      <a
        {...props}
        className={className}
        href={isDisabled ? undefined : props.href}
      >
        {iconPosition == "left" && transformedIcon}
        {isLoading ? <GlowLoadingDots /> : props.label}
        {iconPosition == "right" && transformedIcon}
      </a>
    )
  }
  return (
    <button {...props} className={className} disabled={isDisabled}>
      {iconPosition == "left" && transformedIcon}
      {isLoading ? <GlowLoadingDots /> : <span>{props.label}</span>}
      {iconPosition == "right" && transformedIcon}
    </button>
  )
}

type GlowIconOnlyButtonProps = {
  icon: GlowIconName
  size?: Omit<GlowButtonSize, "xs">
  label?: string
  className?: string
} & React.ComponentProps<"button">

export function GlowIconOnlyButton({
  icon,
  label = icon,
  className,
  size = "base",
  ...props
}: GlowIconOnlyButtonProps) {
  return (
    <button
      {...props}
      className={clsx(
        "pointer group flex flex-col items-center space-y-1",
        className,
      )}
      aria-label={label}
    >
      <div
        className={clsx(
          size === "base" ? "h-12 w-12" : "h-10 w-10",
          "grid place-items-center rounded-full border border-gray-500 bg-transparent opacity-20 group-hover:opacity-60",
        )}
      >
        <GlowIcon name={icon} className="h-6 w-6" />
      </div>
    </button>
  )
}

type GlowButtonAnimations = "slide-left"

type GlowAnimatedButtonProps = {
  isDisabled?: boolean
  animation?: GlowButtonAnimations
  className?: string
  label?: string
  size?: GlowButtonSize
} & React.ComponentProps<"button">

const animationStyleRecord: Record<
  GlowButtonAnimations,
  { button: string; text?: string }
> = {
  "slide-left": {
    button: clsx(
      "bg-gray-500 border-gray-500 text-white transition-[background-color] overflow-hidden duration-300 ease-linear",
      "hover:bg-virtuous-green-400 hover:border-virtuous-green-400 active:bg-virtuous-green-400 active:border-virtuous-green-400",
      "after:absolute after:top-0 after:left-0 after:w-full after:h-full after:z-0 after:bg-virtuous-green-400 after:rounded-full after:translate-x-[-101%] hover:after:translate-x-0 hover:after:duration-300 hover:after:ease-in-out active:after:translate-x-0 active:after:duration-300 active:after:ease-in-out",
      "focus:outline-2 focus:outline-serene-blue-400",
    ),
    text: "relative z-sticky-banner",
  },
}

export function GlowAnimatedButton({
  animation = "slide-left",
  className,
  label,
  size = "base",
  isDisabled,
  ...props
}: GlowAnimatedButtonProps) {
  return (
    <button
      {...props}
      disabled={isDisabled}
      className={clsx(
        "z-sticky-banner group relative rounded-full border-2 px-3.5 outline-offset-0",
        isDisabled
          ? variantStyleRecord["primary"]
          : animationStyleRecord[animation].button,
        isDisabled && "cursor-not-allowed opacity-30",
        resolveSize(size, "primary"),
        className,
      )}
      aria-label={label}
    >
      <span className={clsx(animationStyleRecord[animation].text)}>
        {label}
      </span>
    </button>
  )
}

type GlowNonInteractiveButtonProps = {
  label: string
  size?: GlowButtonSize
  icon?: React.ReactElement
  iconName?: GlowIconName
  iconPosition?: GlowButtonIconPosition
  variant?: GlowButtonVariant
  margin?: GlowResponsiveSpacing
  padding?: GlowResponsiveSpacing
} & React.ComponentProps<"span">

export function GlowNonInteractiveButton({
  variant = "primary",
  icon,
  iconName,
  iconPosition = "left",
  size = "base",
  ...props
}: GlowNonInteractiveButtonProps) {
  const className = clsx([
    "group",
    "flex gap-2 items-center justify-center",
    "transition-colors duration-75",
    variantStyleRecord[variant],
    variantHoverAndFocusStyleRecord[variant],
    props.className,
    resolveSize(size, variant),
    ...glowSpacingToClassNames(props.padding ?? {}, "p"),
    ...glowSpacingToClassNames(props.margin ?? {}, "m"),
  ])
  const resolvedIcon = icon ?? (iconName && <GlowIcon name={iconName} />)
  const transformedIcon =
    resolvedIcon &&
    React.cloneElement(resolvedIcon, {
      className: clsx([resolvedIcon.props.className, "w-6 h-6"]),
    })

  return (
    <span {...props} className={className}>
      {iconPosition == "left" && transformedIcon}
      {iconPosition == "right" && transformedIcon}
      <span>{props.label}</span>
    </span>
  )
}
