/**
 * @generated SignedSource<<1b5e5cacc2655930a3d619efc76cafa4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageOverview_household$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageOverviewBankAccounts_household" | "SettingsPageOverviewHouse_household" | "SettingsPageOverviewInboxes_household" | "SettingsPageOverviewMembers_household" | "SettingsPageOverviewMembership_household" | "SettingsPageOverviewSavingsMode_household" | "SettingsPageOverviewShareInvite_household">;
  readonly " $fragmentType": "SettingsPageOverview_household";
};
export type SettingsPageOverview_household$key = {
  readonly " $data"?: SettingsPageOverview_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageOverview_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageOverview_household",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageOverviewHouse_household"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageOverviewShareInvite_household"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageOverviewMembership_household"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageOverviewSavingsMode_household"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageOverviewMembers_household"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageOverviewBankAccounts_household"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SettingsPageOverviewInboxes_household"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "e792ad5512fc6b9fec1ce265b625a1a5";

export default node;
