/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from "./tanstack-routes/__root"
import { Route as WaterImport } from "./tanstack-routes/water"
import { Route as WaitlistImport } from "./tanstack-routes/waitlist"
import { Route as UploadDocumentImport } from "./tanstack-routes/upload-document"
import { Route as TelevisionImport } from "./tanstack-routes/television"
import { Route as TImport } from "./tanstack-routes/t"
import { Route as SignUpImport } from "./tanstack-routes/sign-up"
import { Route as SavingsImport } from "./tanstack-routes/savings"
import { Route as ResetPasswordImport } from "./tanstack-routes/reset-password"
import { Route as ReferralsImport } from "./tanstack-routes/referrals"
import { Route as PayriseImport } from "./tanstack-routes/payrise"
import { Route as OutlookSsoImport } from "./tanstack-routes/outlook-sso"
import { Route as NotificationsImport } from "./tanstack-routes/notifications"
import { Route as MobileHikesImport } from "./tanstack-routes/mobile-hikes"
import { Route as ManageAccountImport } from "./tanstack-routes/manage-account"
import { Route as LoginImport } from "./tanstack-routes/login"
import { Route as HomeInsuranceImport } from "./tanstack-routes/home-insurance"
import { Route as GlowImport } from "./tanstack-routes/glow"
import { Route as GateImport } from "./tanstack-routes/gate"
import { Route as ForgotPasswordImport } from "./tanstack-routes/forgot-password"
import { Route as EnergyForecastImport } from "./tanstack-routes/energy-forecast"
import { Route as DebugImport } from "./tanstack-routes/debug"
import { Route as CouncilTaxImport } from "./tanstack-routes/council-tax"
import { Route as ConnectWhatsappImport } from "./tanstack-routes/connect-whatsapp"
import { Route as CarInsuranceImport } from "./tanstack-routes/car-insurance"
import { Route as IndexImport } from "./tanstack-routes/index"
import { Route as ToolsIndexImport } from "./tanstack-routes/tools.index"
import { Route as SettingsIndexImport } from "./tanstack-routes/settings/index"
import { Route as SavingsPlanIndexImport } from "./tanstack-routes/savings-plan.index"
import { Route as QuestsIndexImport } from "./tanstack-routes/quests.index"
import { Route as OnboardingIndexImport } from "./tanstack-routes/onboarding.index"
import { Route as InductionIndexImport } from "./tanstack-routes/induction.index"
import { Route as DocumentsIndexImport } from "./tanstack-routes/documents.index"
import { Route as WizardMortgageImport } from "./tanstack-routes/wizard.mortgage"
import { Route as WizardMobileImport } from "./tanstack-routes/wizard.mobile"
import { Route as WizardEnergyImport } from "./tanstack-routes/wizard.energy"
import { Route as WizardBroadbandImport } from "./tanstack-routes/wizard.broadband"
import { Route as WidgetsPayriseImport } from "./tanstack-routes/widgets.payrise"
import { Route as WidgetsMobileHikesImport } from "./tanstack-routes/widgets.mobile-hikes"
import { Route as WidgetsEnergyForecastImport } from "./tanstack-routes/widgets.energy-forecast"
import { Route as TypeformIdImport } from "./tanstack-routes/typeform.$id"
import { Route as ToolsPayriseImport } from "./tanstack-routes/tools.payrise"
import { Route as ToolsMobileHikesImport } from "./tanstack-routes/tools.mobile-hikes"
import { Route as ToolsEnergyForecastImport } from "./tanstack-routes/tools.energy-forecast"
import { Route as StatementIdImport } from "./tanstack-routes/statement.$id"
import { Route as SettingsSplatImport } from "./tanstack-routes/settings/$"
import { Route as SessionIdImport } from "./tanstack-routes/session.$id"
import { Route as QuestsQuestIdImport } from "./tanstack-routes/quests.$questId"
import { Route as PreferencesUserIdImport } from "./tanstack-routes/preferences.$userId"
import { Route as OnboardingWhatsAppDetailsImport } from "./tanstack-routes/onboarding.whatsApp-details"
import { Route as OnboardingServiceLevelImport } from "./tanstack-routes/onboarding.service-level"
import { Route as OnboardingBankDetailsImport } from "./tanstack-routes/onboarding.bank-details"
import { Route as OnboardCreateAccountImport } from "./tanstack-routes/onboard.create-account"
import { Route as MortgageIdImport } from "./tanstack-routes/mortgage.$id"
import { Route as MobileIdImport } from "./tanstack-routes/mobile.$id"
import { Route as InductionWhatsAppDetailsImport } from "./tanstack-routes/induction.whatsApp-details"
import { Route as InductionServiceLevelImport } from "./tanstack-routes/induction.service-level"
import { Route as InductionSavingsPlanImport } from "./tanstack-routes/induction.savings-plan"
import { Route as InductionBankDetailsImport } from "./tanstack-routes/induction.bank-details"
import { Route as EnergyIdImport } from "./tanstack-routes/energy.$id"
import { Route as DocumentsIdImport } from "./tanstack-routes/documents.$id"
import { Route as BroadbandIdImport } from "./tanstack-routes/broadband.$id"
import { Route as BankDetailsUserIdImport } from "./tanstack-routes/bank-details.$userId"
import { Route as AddProviderCategoryImport } from "./tanstack-routes/add-provider.$category"
import { Route as RecommendationMobileIdImport } from "./tanstack-routes/recommendation.mobile.$id"
import { Route as RecommendationEnergyIdImport } from "./tanstack-routes/recommendation.energy.$id"
import { Route as RecommendationBroadbandIdImport } from "./tanstack-routes/recommendation.broadband.$id"
import { Route as Budget2024SpringImport } from "./tanstack-routes/budget.2024.spring"
import { Route as Budget2024AutumnImport } from "./tanstack-routes/budget.2024.autumn"
import { Route as WidgetsBudget2024SpringImport } from "./tanstack-routes/widgets.budget.2024.spring"
import { Route as WidgetsBudget2024AutumnImport } from "./tanstack-routes/widgets.budget.2024.autumn"
import { Route as ToolsBudget2024SpringImport } from "./tanstack-routes/tools.budget.2024.spring"
import { Route as ToolsBudget2024AutumnImport } from "./tanstack-routes/tools.budget.2024.autumn"
import { Route as QuestsQuestIdTasksTaskIdImport } from "./tanstack-routes/quests_.$questId.tasks.$taskId"

// Create/Update Routes

const WaterRoute = WaterImport.update({
  id: "/water",
  path: "/water",
  getParentRoute: () => rootRoute,
} as any)

const WaitlistRoute = WaitlistImport.update({
  id: "/waitlist",
  path: "/waitlist",
  getParentRoute: () => rootRoute,
} as any)

const UploadDocumentRoute = UploadDocumentImport.update({
  id: "/upload-document",
  path: "/upload-document",
  getParentRoute: () => rootRoute,
} as any)

const TelevisionRoute = TelevisionImport.update({
  id: "/television",
  path: "/television",
  getParentRoute: () => rootRoute,
} as any)

const TRoute = TImport.update({
  id: "/t",
  path: "/t",
  getParentRoute: () => rootRoute,
} as any)

const SignUpRoute = SignUpImport.update({
  id: "/sign-up",
  path: "/sign-up",
  getParentRoute: () => rootRoute,
} as any)

const SavingsRoute = SavingsImport.update({
  id: "/savings",
  path: "/savings",
  getParentRoute: () => rootRoute,
} as any)

const ResetPasswordRoute = ResetPasswordImport.update({
  id: "/reset-password",
  path: "/reset-password",
  getParentRoute: () => rootRoute,
} as any)

const ReferralsRoute = ReferralsImport.update({
  id: "/referrals",
  path: "/referrals",
  getParentRoute: () => rootRoute,
} as any)

const PayriseRoute = PayriseImport.update({
  id: "/payrise",
  path: "/payrise",
  getParentRoute: () => rootRoute,
} as any)

const OutlookSsoRoute = OutlookSsoImport.update({
  id: "/outlook-sso",
  path: "/outlook-sso",
  getParentRoute: () => rootRoute,
} as any)

const NotificationsRoute = NotificationsImport.update({
  id: "/notifications",
  path: "/notifications",
  getParentRoute: () => rootRoute,
} as any)

const MobileHikesRoute = MobileHikesImport.update({
  id: "/mobile-hikes",
  path: "/mobile-hikes",
  getParentRoute: () => rootRoute,
} as any)

const ManageAccountRoute = ManageAccountImport.update({
  id: "/manage-account",
  path: "/manage-account",
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: "/login",
  path: "/login",
  getParentRoute: () => rootRoute,
} as any)

const HomeInsuranceRoute = HomeInsuranceImport.update({
  id: "/home-insurance",
  path: "/home-insurance",
  getParentRoute: () => rootRoute,
} as any)

const GlowRoute = GlowImport.update({
  id: "/glow",
  path: "/glow",
  getParentRoute: () => rootRoute,
} as any)

const GateRoute = GateImport.update({
  id: "/gate",
  path: "/gate",
  getParentRoute: () => rootRoute,
} as any)

const ForgotPasswordRoute = ForgotPasswordImport.update({
  id: "/forgot-password",
  path: "/forgot-password",
  getParentRoute: () => rootRoute,
} as any)

const EnergyForecastRoute = EnergyForecastImport.update({
  id: "/energy-forecast",
  path: "/energy-forecast",
  getParentRoute: () => rootRoute,
} as any)

const DebugRoute = DebugImport.update({
  id: "/debug",
  path: "/debug",
  getParentRoute: () => rootRoute,
} as any)

const CouncilTaxRoute = CouncilTaxImport.update({
  id: "/council-tax",
  path: "/council-tax",
  getParentRoute: () => rootRoute,
} as any)

const ConnectWhatsappRoute = ConnectWhatsappImport.update({
  id: "/connect-whatsapp",
  path: "/connect-whatsapp",
  getParentRoute: () => rootRoute,
} as any)

const CarInsuranceRoute = CarInsuranceImport.update({
  id: "/car-insurance",
  path: "/car-insurance",
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => rootRoute,
} as any)

const ToolsIndexRoute = ToolsIndexImport.update({
  id: "/tools/",
  path: "/tools/",
  getParentRoute: () => rootRoute,
} as any)

const SettingsIndexRoute = SettingsIndexImport.update({
  id: "/settings/",
  path: "/settings/",
  getParentRoute: () => rootRoute,
} as any)

const SavingsPlanIndexRoute = SavingsPlanIndexImport.update({
  id: "/savings-plan/",
  path: "/savings-plan/",
  getParentRoute: () => rootRoute,
} as any)

const QuestsIndexRoute = QuestsIndexImport.update({
  id: "/quests/",
  path: "/quests/",
  getParentRoute: () => rootRoute,
} as any)

const OnboardingIndexRoute = OnboardingIndexImport.update({
  id: "/onboarding/",
  path: "/onboarding/",
  getParentRoute: () => rootRoute,
} as any)

const InductionIndexRoute = InductionIndexImport.update({
  id: "/induction/",
  path: "/induction/",
  getParentRoute: () => rootRoute,
} as any)

const DocumentsIndexRoute = DocumentsIndexImport.update({
  id: "/documents/",
  path: "/documents/",
  getParentRoute: () => rootRoute,
} as any)

const WizardMortgageRoute = WizardMortgageImport.update({
  id: "/wizard/mortgage",
  path: "/wizard/mortgage",
  getParentRoute: () => rootRoute,
} as any)

const WizardMobileRoute = WizardMobileImport.update({
  id: "/wizard/mobile",
  path: "/wizard/mobile",
  getParentRoute: () => rootRoute,
} as any)

const WizardEnergyRoute = WizardEnergyImport.update({
  id: "/wizard/energy",
  path: "/wizard/energy",
  getParentRoute: () => rootRoute,
} as any)

const WizardBroadbandRoute = WizardBroadbandImport.update({
  id: "/wizard/broadband",
  path: "/wizard/broadband",
  getParentRoute: () => rootRoute,
} as any)

const WidgetsPayriseRoute = WidgetsPayriseImport.update({
  id: "/widgets/payrise",
  path: "/widgets/payrise",
  getParentRoute: () => rootRoute,
} as any)

const WidgetsMobileHikesRoute = WidgetsMobileHikesImport.update({
  id: "/widgets/mobile-hikes",
  path: "/widgets/mobile-hikes",
  getParentRoute: () => rootRoute,
} as any)

const WidgetsEnergyForecastRoute = WidgetsEnergyForecastImport.update({
  id: "/widgets/energy-forecast",
  path: "/widgets/energy-forecast",
  getParentRoute: () => rootRoute,
} as any)

const TypeformIdRoute = TypeformIdImport.update({
  id: "/typeform/$id",
  path: "/typeform/$id",
  getParentRoute: () => rootRoute,
} as any)

const ToolsPayriseRoute = ToolsPayriseImport.update({
  id: "/tools/payrise",
  path: "/tools/payrise",
  getParentRoute: () => rootRoute,
} as any)

const ToolsMobileHikesRoute = ToolsMobileHikesImport.update({
  id: "/tools/mobile-hikes",
  path: "/tools/mobile-hikes",
  getParentRoute: () => rootRoute,
} as any)

const ToolsEnergyForecastRoute = ToolsEnergyForecastImport.update({
  id: "/tools/energy-forecast",
  path: "/tools/energy-forecast",
  getParentRoute: () => rootRoute,
} as any)

const StatementIdRoute = StatementIdImport.update({
  id: "/statement/$id",
  path: "/statement/$id",
  getParentRoute: () => rootRoute,
} as any)

const SettingsSplatRoute = SettingsSplatImport.update({
  id: "/settings/$",
  path: "/settings/$",
  getParentRoute: () => rootRoute,
} as any)

const SessionIdRoute = SessionIdImport.update({
  id: "/session/$id",
  path: "/session/$id",
  getParentRoute: () => rootRoute,
} as any)

const QuestsQuestIdRoute = QuestsQuestIdImport.update({
  id: "/quests/$questId",
  path: "/quests/$questId",
  getParentRoute: () => rootRoute,
} as any)

const PreferencesUserIdRoute = PreferencesUserIdImport.update({
  id: "/preferences/$userId",
  path: "/preferences/$userId",
  getParentRoute: () => rootRoute,
} as any)

const OnboardingWhatsAppDetailsRoute = OnboardingWhatsAppDetailsImport.update({
  id: "/onboarding/whatsApp-details",
  path: "/onboarding/whatsApp-details",
  getParentRoute: () => rootRoute,
} as any)

const OnboardingServiceLevelRoute = OnboardingServiceLevelImport.update({
  id: "/onboarding/service-level",
  path: "/onboarding/service-level",
  getParentRoute: () => rootRoute,
} as any)

const OnboardingBankDetailsRoute = OnboardingBankDetailsImport.update({
  id: "/onboarding/bank-details",
  path: "/onboarding/bank-details",
  getParentRoute: () => rootRoute,
} as any)

const OnboardCreateAccountRoute = OnboardCreateAccountImport.update({
  id: "/onboard/create-account",
  path: "/onboard/create-account",
  getParentRoute: () => rootRoute,
} as any)

const MortgageIdRoute = MortgageIdImport.update({
  id: "/mortgage/$id",
  path: "/mortgage/$id",
  getParentRoute: () => rootRoute,
} as any)

const MobileIdRoute = MobileIdImport.update({
  id: "/mobile/$id",
  path: "/mobile/$id",
  getParentRoute: () => rootRoute,
} as any)

const InductionWhatsAppDetailsRoute = InductionWhatsAppDetailsImport.update({
  id: "/induction/whatsApp-details",
  path: "/induction/whatsApp-details",
  getParentRoute: () => rootRoute,
} as any)

const InductionServiceLevelRoute = InductionServiceLevelImport.update({
  id: "/induction/service-level",
  path: "/induction/service-level",
  getParentRoute: () => rootRoute,
} as any)

const InductionSavingsPlanRoute = InductionSavingsPlanImport.update({
  id: "/induction/savings-plan",
  path: "/induction/savings-plan",
  getParentRoute: () => rootRoute,
} as any)

const InductionBankDetailsRoute = InductionBankDetailsImport.update({
  id: "/induction/bank-details",
  path: "/induction/bank-details",
  getParentRoute: () => rootRoute,
} as any)

const EnergyIdRoute = EnergyIdImport.update({
  id: "/energy/$id",
  path: "/energy/$id",
  getParentRoute: () => rootRoute,
} as any)

const DocumentsIdRoute = DocumentsIdImport.update({
  id: "/documents/$id",
  path: "/documents/$id",
  getParentRoute: () => rootRoute,
} as any)

const BroadbandIdRoute = BroadbandIdImport.update({
  id: "/broadband/$id",
  path: "/broadband/$id",
  getParentRoute: () => rootRoute,
} as any)

const BankDetailsUserIdRoute = BankDetailsUserIdImport.update({
  id: "/bank-details/$userId",
  path: "/bank-details/$userId",
  getParentRoute: () => rootRoute,
} as any)

const AddProviderCategoryRoute = AddProviderCategoryImport.update({
  id: "/add-provider/$category",
  path: "/add-provider/$category",
  getParentRoute: () => rootRoute,
} as any)

const RecommendationMobileIdRoute = RecommendationMobileIdImport.update({
  id: "/recommendation/mobile/$id",
  path: "/recommendation/mobile/$id",
  getParentRoute: () => rootRoute,
} as any)

const RecommendationEnergyIdRoute = RecommendationEnergyIdImport.update({
  id: "/recommendation/energy/$id",
  path: "/recommendation/energy/$id",
  getParentRoute: () => rootRoute,
} as any)

const RecommendationBroadbandIdRoute = RecommendationBroadbandIdImport.update({
  id: "/recommendation/broadband/$id",
  path: "/recommendation/broadband/$id",
  getParentRoute: () => rootRoute,
} as any)

const Budget2024SpringRoute = Budget2024SpringImport.update({
  id: "/budget/2024/spring",
  path: "/budget/2024/spring",
  getParentRoute: () => rootRoute,
} as any)

const Budget2024AutumnRoute = Budget2024AutumnImport.update({
  id: "/budget/2024/autumn",
  path: "/budget/2024/autumn",
  getParentRoute: () => rootRoute,
} as any)

const WidgetsBudget2024SpringRoute = WidgetsBudget2024SpringImport.update({
  id: "/widgets/budget/2024/spring",
  path: "/widgets/budget/2024/spring",
  getParentRoute: () => rootRoute,
} as any)

const WidgetsBudget2024AutumnRoute = WidgetsBudget2024AutumnImport.update({
  id: "/widgets/budget/2024/autumn",
  path: "/widgets/budget/2024/autumn",
  getParentRoute: () => rootRoute,
} as any)

const ToolsBudget2024SpringRoute = ToolsBudget2024SpringImport.update({
  id: "/tools/budget/2024/spring",
  path: "/tools/budget/2024/spring",
  getParentRoute: () => rootRoute,
} as any)

const ToolsBudget2024AutumnRoute = ToolsBudget2024AutumnImport.update({
  id: "/tools/budget/2024/autumn",
  path: "/tools/budget/2024/autumn",
  getParentRoute: () => rootRoute,
} as any)

const QuestsQuestIdTasksTaskIdRoute = QuestsQuestIdTasksTaskIdImport.update({
  id: "/quests_/$questId/tasks/$taskId",
  path: "/quests/$questId/tasks/$taskId",
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/": {
      id: "/"
      path: "/"
      fullPath: "/"
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    "/car-insurance": {
      id: "/car-insurance"
      path: "/car-insurance"
      fullPath: "/car-insurance"
      preLoaderRoute: typeof CarInsuranceImport
      parentRoute: typeof rootRoute
    }
    "/connect-whatsapp": {
      id: "/connect-whatsapp"
      path: "/connect-whatsapp"
      fullPath: "/connect-whatsapp"
      preLoaderRoute: typeof ConnectWhatsappImport
      parentRoute: typeof rootRoute
    }
    "/council-tax": {
      id: "/council-tax"
      path: "/council-tax"
      fullPath: "/council-tax"
      preLoaderRoute: typeof CouncilTaxImport
      parentRoute: typeof rootRoute
    }
    "/debug": {
      id: "/debug"
      path: "/debug"
      fullPath: "/debug"
      preLoaderRoute: typeof DebugImport
      parentRoute: typeof rootRoute
    }
    "/energy-forecast": {
      id: "/energy-forecast"
      path: "/energy-forecast"
      fullPath: "/energy-forecast"
      preLoaderRoute: typeof EnergyForecastImport
      parentRoute: typeof rootRoute
    }
    "/forgot-password": {
      id: "/forgot-password"
      path: "/forgot-password"
      fullPath: "/forgot-password"
      preLoaderRoute: typeof ForgotPasswordImport
      parentRoute: typeof rootRoute
    }
    "/gate": {
      id: "/gate"
      path: "/gate"
      fullPath: "/gate"
      preLoaderRoute: typeof GateImport
      parentRoute: typeof rootRoute
    }
    "/glow": {
      id: "/glow"
      path: "/glow"
      fullPath: "/glow"
      preLoaderRoute: typeof GlowImport
      parentRoute: typeof rootRoute
    }
    "/home-insurance": {
      id: "/home-insurance"
      path: "/home-insurance"
      fullPath: "/home-insurance"
      preLoaderRoute: typeof HomeInsuranceImport
      parentRoute: typeof rootRoute
    }
    "/login": {
      id: "/login"
      path: "/login"
      fullPath: "/login"
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    "/manage-account": {
      id: "/manage-account"
      path: "/manage-account"
      fullPath: "/manage-account"
      preLoaderRoute: typeof ManageAccountImport
      parentRoute: typeof rootRoute
    }
    "/mobile-hikes": {
      id: "/mobile-hikes"
      path: "/mobile-hikes"
      fullPath: "/mobile-hikes"
      preLoaderRoute: typeof MobileHikesImport
      parentRoute: typeof rootRoute
    }
    "/notifications": {
      id: "/notifications"
      path: "/notifications"
      fullPath: "/notifications"
      preLoaderRoute: typeof NotificationsImport
      parentRoute: typeof rootRoute
    }
    "/outlook-sso": {
      id: "/outlook-sso"
      path: "/outlook-sso"
      fullPath: "/outlook-sso"
      preLoaderRoute: typeof OutlookSsoImport
      parentRoute: typeof rootRoute
    }
    "/payrise": {
      id: "/payrise"
      path: "/payrise"
      fullPath: "/payrise"
      preLoaderRoute: typeof PayriseImport
      parentRoute: typeof rootRoute
    }
    "/referrals": {
      id: "/referrals"
      path: "/referrals"
      fullPath: "/referrals"
      preLoaderRoute: typeof ReferralsImport
      parentRoute: typeof rootRoute
    }
    "/reset-password": {
      id: "/reset-password"
      path: "/reset-password"
      fullPath: "/reset-password"
      preLoaderRoute: typeof ResetPasswordImport
      parentRoute: typeof rootRoute
    }
    "/savings": {
      id: "/savings"
      path: "/savings"
      fullPath: "/savings"
      preLoaderRoute: typeof SavingsImport
      parentRoute: typeof rootRoute
    }
    "/sign-up": {
      id: "/sign-up"
      path: "/sign-up"
      fullPath: "/sign-up"
      preLoaderRoute: typeof SignUpImport
      parentRoute: typeof rootRoute
    }
    "/t": {
      id: "/t"
      path: "/t"
      fullPath: "/t"
      preLoaderRoute: typeof TImport
      parentRoute: typeof rootRoute
    }
    "/television": {
      id: "/television"
      path: "/television"
      fullPath: "/television"
      preLoaderRoute: typeof TelevisionImport
      parentRoute: typeof rootRoute
    }
    "/upload-document": {
      id: "/upload-document"
      path: "/upload-document"
      fullPath: "/upload-document"
      preLoaderRoute: typeof UploadDocumentImport
      parentRoute: typeof rootRoute
    }
    "/waitlist": {
      id: "/waitlist"
      path: "/waitlist"
      fullPath: "/waitlist"
      preLoaderRoute: typeof WaitlistImport
      parentRoute: typeof rootRoute
    }
    "/water": {
      id: "/water"
      path: "/water"
      fullPath: "/water"
      preLoaderRoute: typeof WaterImport
      parentRoute: typeof rootRoute
    }
    "/add-provider/$category": {
      id: "/add-provider/$category"
      path: "/add-provider/$category"
      fullPath: "/add-provider/$category"
      preLoaderRoute: typeof AddProviderCategoryImport
      parentRoute: typeof rootRoute
    }
    "/bank-details/$userId": {
      id: "/bank-details/$userId"
      path: "/bank-details/$userId"
      fullPath: "/bank-details/$userId"
      preLoaderRoute: typeof BankDetailsUserIdImport
      parentRoute: typeof rootRoute
    }
    "/broadband/$id": {
      id: "/broadband/$id"
      path: "/broadband/$id"
      fullPath: "/broadband/$id"
      preLoaderRoute: typeof BroadbandIdImport
      parentRoute: typeof rootRoute
    }
    "/documents/$id": {
      id: "/documents/$id"
      path: "/documents/$id"
      fullPath: "/documents/$id"
      preLoaderRoute: typeof DocumentsIdImport
      parentRoute: typeof rootRoute
    }
    "/energy/$id": {
      id: "/energy/$id"
      path: "/energy/$id"
      fullPath: "/energy/$id"
      preLoaderRoute: typeof EnergyIdImport
      parentRoute: typeof rootRoute
    }
    "/induction/bank-details": {
      id: "/induction/bank-details"
      path: "/induction/bank-details"
      fullPath: "/induction/bank-details"
      preLoaderRoute: typeof InductionBankDetailsImport
      parentRoute: typeof rootRoute
    }
    "/induction/savings-plan": {
      id: "/induction/savings-plan"
      path: "/induction/savings-plan"
      fullPath: "/induction/savings-plan"
      preLoaderRoute: typeof InductionSavingsPlanImport
      parentRoute: typeof rootRoute
    }
    "/induction/service-level": {
      id: "/induction/service-level"
      path: "/induction/service-level"
      fullPath: "/induction/service-level"
      preLoaderRoute: typeof InductionServiceLevelImport
      parentRoute: typeof rootRoute
    }
    "/induction/whatsApp-details": {
      id: "/induction/whatsApp-details"
      path: "/induction/whatsApp-details"
      fullPath: "/induction/whatsApp-details"
      preLoaderRoute: typeof InductionWhatsAppDetailsImport
      parentRoute: typeof rootRoute
    }
    "/mobile/$id": {
      id: "/mobile/$id"
      path: "/mobile/$id"
      fullPath: "/mobile/$id"
      preLoaderRoute: typeof MobileIdImport
      parentRoute: typeof rootRoute
    }
    "/mortgage/$id": {
      id: "/mortgage/$id"
      path: "/mortgage/$id"
      fullPath: "/mortgage/$id"
      preLoaderRoute: typeof MortgageIdImport
      parentRoute: typeof rootRoute
    }
    "/onboard/create-account": {
      id: "/onboard/create-account"
      path: "/onboard/create-account"
      fullPath: "/onboard/create-account"
      preLoaderRoute: typeof OnboardCreateAccountImport
      parentRoute: typeof rootRoute
    }
    "/onboarding/bank-details": {
      id: "/onboarding/bank-details"
      path: "/onboarding/bank-details"
      fullPath: "/onboarding/bank-details"
      preLoaderRoute: typeof OnboardingBankDetailsImport
      parentRoute: typeof rootRoute
    }
    "/onboarding/service-level": {
      id: "/onboarding/service-level"
      path: "/onboarding/service-level"
      fullPath: "/onboarding/service-level"
      preLoaderRoute: typeof OnboardingServiceLevelImport
      parentRoute: typeof rootRoute
    }
    "/onboarding/whatsApp-details": {
      id: "/onboarding/whatsApp-details"
      path: "/onboarding/whatsApp-details"
      fullPath: "/onboarding/whatsApp-details"
      preLoaderRoute: typeof OnboardingWhatsAppDetailsImport
      parentRoute: typeof rootRoute
    }
    "/preferences/$userId": {
      id: "/preferences/$userId"
      path: "/preferences/$userId"
      fullPath: "/preferences/$userId"
      preLoaderRoute: typeof PreferencesUserIdImport
      parentRoute: typeof rootRoute
    }
    "/quests/$questId": {
      id: "/quests/$questId"
      path: "/quests/$questId"
      fullPath: "/quests/$questId"
      preLoaderRoute: typeof QuestsQuestIdImport
      parentRoute: typeof rootRoute
    }
    "/session/$id": {
      id: "/session/$id"
      path: "/session/$id"
      fullPath: "/session/$id"
      preLoaderRoute: typeof SessionIdImport
      parentRoute: typeof rootRoute
    }
    "/settings/$": {
      id: "/settings/$"
      path: "/settings/$"
      fullPath: "/settings/$"
      preLoaderRoute: typeof SettingsSplatImport
      parentRoute: typeof rootRoute
    }
    "/statement/$id": {
      id: "/statement/$id"
      path: "/statement/$id"
      fullPath: "/statement/$id"
      preLoaderRoute: typeof StatementIdImport
      parentRoute: typeof rootRoute
    }
    "/tools/energy-forecast": {
      id: "/tools/energy-forecast"
      path: "/tools/energy-forecast"
      fullPath: "/tools/energy-forecast"
      preLoaderRoute: typeof ToolsEnergyForecastImport
      parentRoute: typeof rootRoute
    }
    "/tools/mobile-hikes": {
      id: "/tools/mobile-hikes"
      path: "/tools/mobile-hikes"
      fullPath: "/tools/mobile-hikes"
      preLoaderRoute: typeof ToolsMobileHikesImport
      parentRoute: typeof rootRoute
    }
    "/tools/payrise": {
      id: "/tools/payrise"
      path: "/tools/payrise"
      fullPath: "/tools/payrise"
      preLoaderRoute: typeof ToolsPayriseImport
      parentRoute: typeof rootRoute
    }
    "/typeform/$id": {
      id: "/typeform/$id"
      path: "/typeform/$id"
      fullPath: "/typeform/$id"
      preLoaderRoute: typeof TypeformIdImport
      parentRoute: typeof rootRoute
    }
    "/widgets/energy-forecast": {
      id: "/widgets/energy-forecast"
      path: "/widgets/energy-forecast"
      fullPath: "/widgets/energy-forecast"
      preLoaderRoute: typeof WidgetsEnergyForecastImport
      parentRoute: typeof rootRoute
    }
    "/widgets/mobile-hikes": {
      id: "/widgets/mobile-hikes"
      path: "/widgets/mobile-hikes"
      fullPath: "/widgets/mobile-hikes"
      preLoaderRoute: typeof WidgetsMobileHikesImport
      parentRoute: typeof rootRoute
    }
    "/widgets/payrise": {
      id: "/widgets/payrise"
      path: "/widgets/payrise"
      fullPath: "/widgets/payrise"
      preLoaderRoute: typeof WidgetsPayriseImport
      parentRoute: typeof rootRoute
    }
    "/wizard/broadband": {
      id: "/wizard/broadband"
      path: "/wizard/broadband"
      fullPath: "/wizard/broadband"
      preLoaderRoute: typeof WizardBroadbandImport
      parentRoute: typeof rootRoute
    }
    "/wizard/energy": {
      id: "/wizard/energy"
      path: "/wizard/energy"
      fullPath: "/wizard/energy"
      preLoaderRoute: typeof WizardEnergyImport
      parentRoute: typeof rootRoute
    }
    "/wizard/mobile": {
      id: "/wizard/mobile"
      path: "/wizard/mobile"
      fullPath: "/wizard/mobile"
      preLoaderRoute: typeof WizardMobileImport
      parentRoute: typeof rootRoute
    }
    "/wizard/mortgage": {
      id: "/wizard/mortgage"
      path: "/wizard/mortgage"
      fullPath: "/wizard/mortgage"
      preLoaderRoute: typeof WizardMortgageImport
      parentRoute: typeof rootRoute
    }
    "/documents/": {
      id: "/documents/"
      path: "/documents"
      fullPath: "/documents"
      preLoaderRoute: typeof DocumentsIndexImport
      parentRoute: typeof rootRoute
    }
    "/induction/": {
      id: "/induction/"
      path: "/induction"
      fullPath: "/induction"
      preLoaderRoute: typeof InductionIndexImport
      parentRoute: typeof rootRoute
    }
    "/onboarding/": {
      id: "/onboarding/"
      path: "/onboarding"
      fullPath: "/onboarding"
      preLoaderRoute: typeof OnboardingIndexImport
      parentRoute: typeof rootRoute
    }
    "/quests/": {
      id: "/quests/"
      path: "/quests"
      fullPath: "/quests"
      preLoaderRoute: typeof QuestsIndexImport
      parentRoute: typeof rootRoute
    }
    "/savings-plan/": {
      id: "/savings-plan/"
      path: "/savings-plan"
      fullPath: "/savings-plan"
      preLoaderRoute: typeof SavingsPlanIndexImport
      parentRoute: typeof rootRoute
    }
    "/settings/": {
      id: "/settings/"
      path: "/settings"
      fullPath: "/settings"
      preLoaderRoute: typeof SettingsIndexImport
      parentRoute: typeof rootRoute
    }
    "/tools/": {
      id: "/tools/"
      path: "/tools"
      fullPath: "/tools"
      preLoaderRoute: typeof ToolsIndexImport
      parentRoute: typeof rootRoute
    }
    "/budget/2024/autumn": {
      id: "/budget/2024/autumn"
      path: "/budget/2024/autumn"
      fullPath: "/budget/2024/autumn"
      preLoaderRoute: typeof Budget2024AutumnImport
      parentRoute: typeof rootRoute
    }
    "/budget/2024/spring": {
      id: "/budget/2024/spring"
      path: "/budget/2024/spring"
      fullPath: "/budget/2024/spring"
      preLoaderRoute: typeof Budget2024SpringImport
      parentRoute: typeof rootRoute
    }
    "/recommendation/broadband/$id": {
      id: "/recommendation/broadband/$id"
      path: "/recommendation/broadband/$id"
      fullPath: "/recommendation/broadband/$id"
      preLoaderRoute: typeof RecommendationBroadbandIdImport
      parentRoute: typeof rootRoute
    }
    "/recommendation/energy/$id": {
      id: "/recommendation/energy/$id"
      path: "/recommendation/energy/$id"
      fullPath: "/recommendation/energy/$id"
      preLoaderRoute: typeof RecommendationEnergyIdImport
      parentRoute: typeof rootRoute
    }
    "/recommendation/mobile/$id": {
      id: "/recommendation/mobile/$id"
      path: "/recommendation/mobile/$id"
      fullPath: "/recommendation/mobile/$id"
      preLoaderRoute: typeof RecommendationMobileIdImport
      parentRoute: typeof rootRoute
    }
    "/quests_/$questId/tasks/$taskId": {
      id: "/quests_/$questId/tasks/$taskId"
      path: "/quests/$questId/tasks/$taskId"
      fullPath: "/quests/$questId/tasks/$taskId"
      preLoaderRoute: typeof QuestsQuestIdTasksTaskIdImport
      parentRoute: typeof rootRoute
    }
    "/tools/budget/2024/autumn": {
      id: "/tools/budget/2024/autumn"
      path: "/tools/budget/2024/autumn"
      fullPath: "/tools/budget/2024/autumn"
      preLoaderRoute: typeof ToolsBudget2024AutumnImport
      parentRoute: typeof rootRoute
    }
    "/tools/budget/2024/spring": {
      id: "/tools/budget/2024/spring"
      path: "/tools/budget/2024/spring"
      fullPath: "/tools/budget/2024/spring"
      preLoaderRoute: typeof ToolsBudget2024SpringImport
      parentRoute: typeof rootRoute
    }
    "/widgets/budget/2024/autumn": {
      id: "/widgets/budget/2024/autumn"
      path: "/widgets/budget/2024/autumn"
      fullPath: "/widgets/budget/2024/autumn"
      preLoaderRoute: typeof WidgetsBudget2024AutumnImport
      parentRoute: typeof rootRoute
    }
    "/widgets/budget/2024/spring": {
      id: "/widgets/budget/2024/spring"
      path: "/widgets/budget/2024/spring"
      fullPath: "/widgets/budget/2024/spring"
      preLoaderRoute: typeof WidgetsBudget2024SpringImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  "/": typeof IndexRoute
  "/car-insurance": typeof CarInsuranceRoute
  "/connect-whatsapp": typeof ConnectWhatsappRoute
  "/council-tax": typeof CouncilTaxRoute
  "/debug": typeof DebugRoute
  "/energy-forecast": typeof EnergyForecastRoute
  "/forgot-password": typeof ForgotPasswordRoute
  "/gate": typeof GateRoute
  "/glow": typeof GlowRoute
  "/home-insurance": typeof HomeInsuranceRoute
  "/login": typeof LoginRoute
  "/manage-account": typeof ManageAccountRoute
  "/mobile-hikes": typeof MobileHikesRoute
  "/notifications": typeof NotificationsRoute
  "/outlook-sso": typeof OutlookSsoRoute
  "/payrise": typeof PayriseRoute
  "/referrals": typeof ReferralsRoute
  "/reset-password": typeof ResetPasswordRoute
  "/savings": typeof SavingsRoute
  "/sign-up": typeof SignUpRoute
  "/t": typeof TRoute
  "/television": typeof TelevisionRoute
  "/upload-document": typeof UploadDocumentRoute
  "/waitlist": typeof WaitlistRoute
  "/water": typeof WaterRoute
  "/add-provider/$category": typeof AddProviderCategoryRoute
  "/bank-details/$userId": typeof BankDetailsUserIdRoute
  "/broadband/$id": typeof BroadbandIdRoute
  "/documents/$id": typeof DocumentsIdRoute
  "/energy/$id": typeof EnergyIdRoute
  "/induction/bank-details": typeof InductionBankDetailsRoute
  "/induction/savings-plan": typeof InductionSavingsPlanRoute
  "/induction/service-level": typeof InductionServiceLevelRoute
  "/induction/whatsApp-details": typeof InductionWhatsAppDetailsRoute
  "/mobile/$id": typeof MobileIdRoute
  "/mortgage/$id": typeof MortgageIdRoute
  "/onboard/create-account": typeof OnboardCreateAccountRoute
  "/onboarding/bank-details": typeof OnboardingBankDetailsRoute
  "/onboarding/service-level": typeof OnboardingServiceLevelRoute
  "/onboarding/whatsApp-details": typeof OnboardingWhatsAppDetailsRoute
  "/preferences/$userId": typeof PreferencesUserIdRoute
  "/quests/$questId": typeof QuestsQuestIdRoute
  "/session/$id": typeof SessionIdRoute
  "/settings/$": typeof SettingsSplatRoute
  "/statement/$id": typeof StatementIdRoute
  "/tools/energy-forecast": typeof ToolsEnergyForecastRoute
  "/tools/mobile-hikes": typeof ToolsMobileHikesRoute
  "/tools/payrise": typeof ToolsPayriseRoute
  "/typeform/$id": typeof TypeformIdRoute
  "/widgets/energy-forecast": typeof WidgetsEnergyForecastRoute
  "/widgets/mobile-hikes": typeof WidgetsMobileHikesRoute
  "/widgets/payrise": typeof WidgetsPayriseRoute
  "/wizard/broadband": typeof WizardBroadbandRoute
  "/wizard/energy": typeof WizardEnergyRoute
  "/wizard/mobile": typeof WizardMobileRoute
  "/wizard/mortgage": typeof WizardMortgageRoute
  "/documents": typeof DocumentsIndexRoute
  "/induction": typeof InductionIndexRoute
  "/onboarding": typeof OnboardingIndexRoute
  "/quests": typeof QuestsIndexRoute
  "/savings-plan": typeof SavingsPlanIndexRoute
  "/settings": typeof SettingsIndexRoute
  "/tools": typeof ToolsIndexRoute
  "/budget/2024/autumn": typeof Budget2024AutumnRoute
  "/budget/2024/spring": typeof Budget2024SpringRoute
  "/recommendation/broadband/$id": typeof RecommendationBroadbandIdRoute
  "/recommendation/energy/$id": typeof RecommendationEnergyIdRoute
  "/recommendation/mobile/$id": typeof RecommendationMobileIdRoute
  "/quests/$questId/tasks/$taskId": typeof QuestsQuestIdTasksTaskIdRoute
  "/tools/budget/2024/autumn": typeof ToolsBudget2024AutumnRoute
  "/tools/budget/2024/spring": typeof ToolsBudget2024SpringRoute
  "/widgets/budget/2024/autumn": typeof WidgetsBudget2024AutumnRoute
  "/widgets/budget/2024/spring": typeof WidgetsBudget2024SpringRoute
}

export interface FileRoutesByTo {
  "/": typeof IndexRoute
  "/car-insurance": typeof CarInsuranceRoute
  "/connect-whatsapp": typeof ConnectWhatsappRoute
  "/council-tax": typeof CouncilTaxRoute
  "/debug": typeof DebugRoute
  "/energy-forecast": typeof EnergyForecastRoute
  "/forgot-password": typeof ForgotPasswordRoute
  "/gate": typeof GateRoute
  "/glow": typeof GlowRoute
  "/home-insurance": typeof HomeInsuranceRoute
  "/login": typeof LoginRoute
  "/manage-account": typeof ManageAccountRoute
  "/mobile-hikes": typeof MobileHikesRoute
  "/notifications": typeof NotificationsRoute
  "/outlook-sso": typeof OutlookSsoRoute
  "/payrise": typeof PayriseRoute
  "/referrals": typeof ReferralsRoute
  "/reset-password": typeof ResetPasswordRoute
  "/savings": typeof SavingsRoute
  "/sign-up": typeof SignUpRoute
  "/t": typeof TRoute
  "/television": typeof TelevisionRoute
  "/upload-document": typeof UploadDocumentRoute
  "/waitlist": typeof WaitlistRoute
  "/water": typeof WaterRoute
  "/add-provider/$category": typeof AddProviderCategoryRoute
  "/bank-details/$userId": typeof BankDetailsUserIdRoute
  "/broadband/$id": typeof BroadbandIdRoute
  "/documents/$id": typeof DocumentsIdRoute
  "/energy/$id": typeof EnergyIdRoute
  "/induction/bank-details": typeof InductionBankDetailsRoute
  "/induction/savings-plan": typeof InductionSavingsPlanRoute
  "/induction/service-level": typeof InductionServiceLevelRoute
  "/induction/whatsApp-details": typeof InductionWhatsAppDetailsRoute
  "/mobile/$id": typeof MobileIdRoute
  "/mortgage/$id": typeof MortgageIdRoute
  "/onboard/create-account": typeof OnboardCreateAccountRoute
  "/onboarding/bank-details": typeof OnboardingBankDetailsRoute
  "/onboarding/service-level": typeof OnboardingServiceLevelRoute
  "/onboarding/whatsApp-details": typeof OnboardingWhatsAppDetailsRoute
  "/preferences/$userId": typeof PreferencesUserIdRoute
  "/quests/$questId": typeof QuestsQuestIdRoute
  "/session/$id": typeof SessionIdRoute
  "/settings/$": typeof SettingsSplatRoute
  "/statement/$id": typeof StatementIdRoute
  "/tools/energy-forecast": typeof ToolsEnergyForecastRoute
  "/tools/mobile-hikes": typeof ToolsMobileHikesRoute
  "/tools/payrise": typeof ToolsPayriseRoute
  "/typeform/$id": typeof TypeformIdRoute
  "/widgets/energy-forecast": typeof WidgetsEnergyForecastRoute
  "/widgets/mobile-hikes": typeof WidgetsMobileHikesRoute
  "/widgets/payrise": typeof WidgetsPayriseRoute
  "/wizard/broadband": typeof WizardBroadbandRoute
  "/wizard/energy": typeof WizardEnergyRoute
  "/wizard/mobile": typeof WizardMobileRoute
  "/wizard/mortgage": typeof WizardMortgageRoute
  "/documents": typeof DocumentsIndexRoute
  "/induction": typeof InductionIndexRoute
  "/onboarding": typeof OnboardingIndexRoute
  "/quests": typeof QuestsIndexRoute
  "/savings-plan": typeof SavingsPlanIndexRoute
  "/settings": typeof SettingsIndexRoute
  "/tools": typeof ToolsIndexRoute
  "/budget/2024/autumn": typeof Budget2024AutumnRoute
  "/budget/2024/spring": typeof Budget2024SpringRoute
  "/recommendation/broadband/$id": typeof RecommendationBroadbandIdRoute
  "/recommendation/energy/$id": typeof RecommendationEnergyIdRoute
  "/recommendation/mobile/$id": typeof RecommendationMobileIdRoute
  "/quests/$questId/tasks/$taskId": typeof QuestsQuestIdTasksTaskIdRoute
  "/tools/budget/2024/autumn": typeof ToolsBudget2024AutumnRoute
  "/tools/budget/2024/spring": typeof ToolsBudget2024SpringRoute
  "/widgets/budget/2024/autumn": typeof WidgetsBudget2024AutumnRoute
  "/widgets/budget/2024/spring": typeof WidgetsBudget2024SpringRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  "/": typeof IndexRoute
  "/car-insurance": typeof CarInsuranceRoute
  "/connect-whatsapp": typeof ConnectWhatsappRoute
  "/council-tax": typeof CouncilTaxRoute
  "/debug": typeof DebugRoute
  "/energy-forecast": typeof EnergyForecastRoute
  "/forgot-password": typeof ForgotPasswordRoute
  "/gate": typeof GateRoute
  "/glow": typeof GlowRoute
  "/home-insurance": typeof HomeInsuranceRoute
  "/login": typeof LoginRoute
  "/manage-account": typeof ManageAccountRoute
  "/mobile-hikes": typeof MobileHikesRoute
  "/notifications": typeof NotificationsRoute
  "/outlook-sso": typeof OutlookSsoRoute
  "/payrise": typeof PayriseRoute
  "/referrals": typeof ReferralsRoute
  "/reset-password": typeof ResetPasswordRoute
  "/savings": typeof SavingsRoute
  "/sign-up": typeof SignUpRoute
  "/t": typeof TRoute
  "/television": typeof TelevisionRoute
  "/upload-document": typeof UploadDocumentRoute
  "/waitlist": typeof WaitlistRoute
  "/water": typeof WaterRoute
  "/add-provider/$category": typeof AddProviderCategoryRoute
  "/bank-details/$userId": typeof BankDetailsUserIdRoute
  "/broadband/$id": typeof BroadbandIdRoute
  "/documents/$id": typeof DocumentsIdRoute
  "/energy/$id": typeof EnergyIdRoute
  "/induction/bank-details": typeof InductionBankDetailsRoute
  "/induction/savings-plan": typeof InductionSavingsPlanRoute
  "/induction/service-level": typeof InductionServiceLevelRoute
  "/induction/whatsApp-details": typeof InductionWhatsAppDetailsRoute
  "/mobile/$id": typeof MobileIdRoute
  "/mortgage/$id": typeof MortgageIdRoute
  "/onboard/create-account": typeof OnboardCreateAccountRoute
  "/onboarding/bank-details": typeof OnboardingBankDetailsRoute
  "/onboarding/service-level": typeof OnboardingServiceLevelRoute
  "/onboarding/whatsApp-details": typeof OnboardingWhatsAppDetailsRoute
  "/preferences/$userId": typeof PreferencesUserIdRoute
  "/quests/$questId": typeof QuestsQuestIdRoute
  "/session/$id": typeof SessionIdRoute
  "/settings/$": typeof SettingsSplatRoute
  "/statement/$id": typeof StatementIdRoute
  "/tools/energy-forecast": typeof ToolsEnergyForecastRoute
  "/tools/mobile-hikes": typeof ToolsMobileHikesRoute
  "/tools/payrise": typeof ToolsPayriseRoute
  "/typeform/$id": typeof TypeformIdRoute
  "/widgets/energy-forecast": typeof WidgetsEnergyForecastRoute
  "/widgets/mobile-hikes": typeof WidgetsMobileHikesRoute
  "/widgets/payrise": typeof WidgetsPayriseRoute
  "/wizard/broadband": typeof WizardBroadbandRoute
  "/wizard/energy": typeof WizardEnergyRoute
  "/wizard/mobile": typeof WizardMobileRoute
  "/wizard/mortgage": typeof WizardMortgageRoute
  "/documents/": typeof DocumentsIndexRoute
  "/induction/": typeof InductionIndexRoute
  "/onboarding/": typeof OnboardingIndexRoute
  "/quests/": typeof QuestsIndexRoute
  "/savings-plan/": typeof SavingsPlanIndexRoute
  "/settings/": typeof SettingsIndexRoute
  "/tools/": typeof ToolsIndexRoute
  "/budget/2024/autumn": typeof Budget2024AutumnRoute
  "/budget/2024/spring": typeof Budget2024SpringRoute
  "/recommendation/broadband/$id": typeof RecommendationBroadbandIdRoute
  "/recommendation/energy/$id": typeof RecommendationEnergyIdRoute
  "/recommendation/mobile/$id": typeof RecommendationMobileIdRoute
  "/quests_/$questId/tasks/$taskId": typeof QuestsQuestIdTasksTaskIdRoute
  "/tools/budget/2024/autumn": typeof ToolsBudget2024AutumnRoute
  "/tools/budget/2024/spring": typeof ToolsBudget2024SpringRoute
  "/widgets/budget/2024/autumn": typeof WidgetsBudget2024AutumnRoute
  "/widgets/budget/2024/spring": typeof WidgetsBudget2024SpringRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | "/"
    | "/car-insurance"
    | "/connect-whatsapp"
    | "/council-tax"
    | "/debug"
    | "/energy-forecast"
    | "/forgot-password"
    | "/gate"
    | "/glow"
    | "/home-insurance"
    | "/login"
    | "/manage-account"
    | "/mobile-hikes"
    | "/notifications"
    | "/outlook-sso"
    | "/payrise"
    | "/referrals"
    | "/reset-password"
    | "/savings"
    | "/sign-up"
    | "/t"
    | "/television"
    | "/upload-document"
    | "/waitlist"
    | "/water"
    | "/add-provider/$category"
    | "/bank-details/$userId"
    | "/broadband/$id"
    | "/documents/$id"
    | "/energy/$id"
    | "/induction/bank-details"
    | "/induction/savings-plan"
    | "/induction/service-level"
    | "/induction/whatsApp-details"
    | "/mobile/$id"
    | "/mortgage/$id"
    | "/onboard/create-account"
    | "/onboarding/bank-details"
    | "/onboarding/service-level"
    | "/onboarding/whatsApp-details"
    | "/preferences/$userId"
    | "/quests/$questId"
    | "/session/$id"
    | "/settings/$"
    | "/statement/$id"
    | "/tools/energy-forecast"
    | "/tools/mobile-hikes"
    | "/tools/payrise"
    | "/typeform/$id"
    | "/widgets/energy-forecast"
    | "/widgets/mobile-hikes"
    | "/widgets/payrise"
    | "/wizard/broadband"
    | "/wizard/energy"
    | "/wizard/mobile"
    | "/wizard/mortgage"
    | "/documents"
    | "/induction"
    | "/onboarding"
    | "/quests"
    | "/savings-plan"
    | "/settings"
    | "/tools"
    | "/budget/2024/autumn"
    | "/budget/2024/spring"
    | "/recommendation/broadband/$id"
    | "/recommendation/energy/$id"
    | "/recommendation/mobile/$id"
    | "/quests/$questId/tasks/$taskId"
    | "/tools/budget/2024/autumn"
    | "/tools/budget/2024/spring"
    | "/widgets/budget/2024/autumn"
    | "/widgets/budget/2024/spring"
  fileRoutesByTo: FileRoutesByTo
  to:
    | "/"
    | "/car-insurance"
    | "/connect-whatsapp"
    | "/council-tax"
    | "/debug"
    | "/energy-forecast"
    | "/forgot-password"
    | "/gate"
    | "/glow"
    | "/home-insurance"
    | "/login"
    | "/manage-account"
    | "/mobile-hikes"
    | "/notifications"
    | "/outlook-sso"
    | "/payrise"
    | "/referrals"
    | "/reset-password"
    | "/savings"
    | "/sign-up"
    | "/t"
    | "/television"
    | "/upload-document"
    | "/waitlist"
    | "/water"
    | "/add-provider/$category"
    | "/bank-details/$userId"
    | "/broadband/$id"
    | "/documents/$id"
    | "/energy/$id"
    | "/induction/bank-details"
    | "/induction/savings-plan"
    | "/induction/service-level"
    | "/induction/whatsApp-details"
    | "/mobile/$id"
    | "/mortgage/$id"
    | "/onboard/create-account"
    | "/onboarding/bank-details"
    | "/onboarding/service-level"
    | "/onboarding/whatsApp-details"
    | "/preferences/$userId"
    | "/quests/$questId"
    | "/session/$id"
    | "/settings/$"
    | "/statement/$id"
    | "/tools/energy-forecast"
    | "/tools/mobile-hikes"
    | "/tools/payrise"
    | "/typeform/$id"
    | "/widgets/energy-forecast"
    | "/widgets/mobile-hikes"
    | "/widgets/payrise"
    | "/wizard/broadband"
    | "/wizard/energy"
    | "/wizard/mobile"
    | "/wizard/mortgage"
    | "/documents"
    | "/induction"
    | "/onboarding"
    | "/quests"
    | "/savings-plan"
    | "/settings"
    | "/tools"
    | "/budget/2024/autumn"
    | "/budget/2024/spring"
    | "/recommendation/broadband/$id"
    | "/recommendation/energy/$id"
    | "/recommendation/mobile/$id"
    | "/quests/$questId/tasks/$taskId"
    | "/tools/budget/2024/autumn"
    | "/tools/budget/2024/spring"
    | "/widgets/budget/2024/autumn"
    | "/widgets/budget/2024/spring"
  id:
    | "__root__"
    | "/"
    | "/car-insurance"
    | "/connect-whatsapp"
    | "/council-tax"
    | "/debug"
    | "/energy-forecast"
    | "/forgot-password"
    | "/gate"
    | "/glow"
    | "/home-insurance"
    | "/login"
    | "/manage-account"
    | "/mobile-hikes"
    | "/notifications"
    | "/outlook-sso"
    | "/payrise"
    | "/referrals"
    | "/reset-password"
    | "/savings"
    | "/sign-up"
    | "/t"
    | "/television"
    | "/upload-document"
    | "/waitlist"
    | "/water"
    | "/add-provider/$category"
    | "/bank-details/$userId"
    | "/broadband/$id"
    | "/documents/$id"
    | "/energy/$id"
    | "/induction/bank-details"
    | "/induction/savings-plan"
    | "/induction/service-level"
    | "/induction/whatsApp-details"
    | "/mobile/$id"
    | "/mortgage/$id"
    | "/onboard/create-account"
    | "/onboarding/bank-details"
    | "/onboarding/service-level"
    | "/onboarding/whatsApp-details"
    | "/preferences/$userId"
    | "/quests/$questId"
    | "/session/$id"
    | "/settings/$"
    | "/statement/$id"
    | "/tools/energy-forecast"
    | "/tools/mobile-hikes"
    | "/tools/payrise"
    | "/typeform/$id"
    | "/widgets/energy-forecast"
    | "/widgets/mobile-hikes"
    | "/widgets/payrise"
    | "/wizard/broadband"
    | "/wizard/energy"
    | "/wizard/mobile"
    | "/wizard/mortgage"
    | "/documents/"
    | "/induction/"
    | "/onboarding/"
    | "/quests/"
    | "/savings-plan/"
    | "/settings/"
    | "/tools/"
    | "/budget/2024/autumn"
    | "/budget/2024/spring"
    | "/recommendation/broadband/$id"
    | "/recommendation/energy/$id"
    | "/recommendation/mobile/$id"
    | "/quests_/$questId/tasks/$taskId"
    | "/tools/budget/2024/autumn"
    | "/tools/budget/2024/spring"
    | "/widgets/budget/2024/autumn"
    | "/widgets/budget/2024/spring"
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  CarInsuranceRoute: typeof CarInsuranceRoute
  ConnectWhatsappRoute: typeof ConnectWhatsappRoute
  CouncilTaxRoute: typeof CouncilTaxRoute
  DebugRoute: typeof DebugRoute
  EnergyForecastRoute: typeof EnergyForecastRoute
  ForgotPasswordRoute: typeof ForgotPasswordRoute
  GateRoute: typeof GateRoute
  GlowRoute: typeof GlowRoute
  HomeInsuranceRoute: typeof HomeInsuranceRoute
  LoginRoute: typeof LoginRoute
  ManageAccountRoute: typeof ManageAccountRoute
  MobileHikesRoute: typeof MobileHikesRoute
  NotificationsRoute: typeof NotificationsRoute
  OutlookSsoRoute: typeof OutlookSsoRoute
  PayriseRoute: typeof PayriseRoute
  ReferralsRoute: typeof ReferralsRoute
  ResetPasswordRoute: typeof ResetPasswordRoute
  SavingsRoute: typeof SavingsRoute
  SignUpRoute: typeof SignUpRoute
  TRoute: typeof TRoute
  TelevisionRoute: typeof TelevisionRoute
  UploadDocumentRoute: typeof UploadDocumentRoute
  WaitlistRoute: typeof WaitlistRoute
  WaterRoute: typeof WaterRoute
  AddProviderCategoryRoute: typeof AddProviderCategoryRoute
  BankDetailsUserIdRoute: typeof BankDetailsUserIdRoute
  BroadbandIdRoute: typeof BroadbandIdRoute
  DocumentsIdRoute: typeof DocumentsIdRoute
  EnergyIdRoute: typeof EnergyIdRoute
  InductionBankDetailsRoute: typeof InductionBankDetailsRoute
  InductionSavingsPlanRoute: typeof InductionSavingsPlanRoute
  InductionServiceLevelRoute: typeof InductionServiceLevelRoute
  InductionWhatsAppDetailsRoute: typeof InductionWhatsAppDetailsRoute
  MobileIdRoute: typeof MobileIdRoute
  MortgageIdRoute: typeof MortgageIdRoute
  OnboardCreateAccountRoute: typeof OnboardCreateAccountRoute
  OnboardingBankDetailsRoute: typeof OnboardingBankDetailsRoute
  OnboardingServiceLevelRoute: typeof OnboardingServiceLevelRoute
  OnboardingWhatsAppDetailsRoute: typeof OnboardingWhatsAppDetailsRoute
  PreferencesUserIdRoute: typeof PreferencesUserIdRoute
  QuestsQuestIdRoute: typeof QuestsQuestIdRoute
  SessionIdRoute: typeof SessionIdRoute
  SettingsSplatRoute: typeof SettingsSplatRoute
  StatementIdRoute: typeof StatementIdRoute
  ToolsEnergyForecastRoute: typeof ToolsEnergyForecastRoute
  ToolsMobileHikesRoute: typeof ToolsMobileHikesRoute
  ToolsPayriseRoute: typeof ToolsPayriseRoute
  TypeformIdRoute: typeof TypeformIdRoute
  WidgetsEnergyForecastRoute: typeof WidgetsEnergyForecastRoute
  WidgetsMobileHikesRoute: typeof WidgetsMobileHikesRoute
  WidgetsPayriseRoute: typeof WidgetsPayriseRoute
  WizardBroadbandRoute: typeof WizardBroadbandRoute
  WizardEnergyRoute: typeof WizardEnergyRoute
  WizardMobileRoute: typeof WizardMobileRoute
  WizardMortgageRoute: typeof WizardMortgageRoute
  DocumentsIndexRoute: typeof DocumentsIndexRoute
  InductionIndexRoute: typeof InductionIndexRoute
  OnboardingIndexRoute: typeof OnboardingIndexRoute
  QuestsIndexRoute: typeof QuestsIndexRoute
  SavingsPlanIndexRoute: typeof SavingsPlanIndexRoute
  SettingsIndexRoute: typeof SettingsIndexRoute
  ToolsIndexRoute: typeof ToolsIndexRoute
  Budget2024AutumnRoute: typeof Budget2024AutumnRoute
  Budget2024SpringRoute: typeof Budget2024SpringRoute
  RecommendationBroadbandIdRoute: typeof RecommendationBroadbandIdRoute
  RecommendationEnergyIdRoute: typeof RecommendationEnergyIdRoute
  RecommendationMobileIdRoute: typeof RecommendationMobileIdRoute
  QuestsQuestIdTasksTaskIdRoute: typeof QuestsQuestIdTasksTaskIdRoute
  ToolsBudget2024AutumnRoute: typeof ToolsBudget2024AutumnRoute
  ToolsBudget2024SpringRoute: typeof ToolsBudget2024SpringRoute
  WidgetsBudget2024AutumnRoute: typeof WidgetsBudget2024AutumnRoute
  WidgetsBudget2024SpringRoute: typeof WidgetsBudget2024SpringRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  CarInsuranceRoute: CarInsuranceRoute,
  ConnectWhatsappRoute: ConnectWhatsappRoute,
  CouncilTaxRoute: CouncilTaxRoute,
  DebugRoute: DebugRoute,
  EnergyForecastRoute: EnergyForecastRoute,
  ForgotPasswordRoute: ForgotPasswordRoute,
  GateRoute: GateRoute,
  GlowRoute: GlowRoute,
  HomeInsuranceRoute: HomeInsuranceRoute,
  LoginRoute: LoginRoute,
  ManageAccountRoute: ManageAccountRoute,
  MobileHikesRoute: MobileHikesRoute,
  NotificationsRoute: NotificationsRoute,
  OutlookSsoRoute: OutlookSsoRoute,
  PayriseRoute: PayriseRoute,
  ReferralsRoute: ReferralsRoute,
  ResetPasswordRoute: ResetPasswordRoute,
  SavingsRoute: SavingsRoute,
  SignUpRoute: SignUpRoute,
  TRoute: TRoute,
  TelevisionRoute: TelevisionRoute,
  UploadDocumentRoute: UploadDocumentRoute,
  WaitlistRoute: WaitlistRoute,
  WaterRoute: WaterRoute,
  AddProviderCategoryRoute: AddProviderCategoryRoute,
  BankDetailsUserIdRoute: BankDetailsUserIdRoute,
  BroadbandIdRoute: BroadbandIdRoute,
  DocumentsIdRoute: DocumentsIdRoute,
  EnergyIdRoute: EnergyIdRoute,
  InductionBankDetailsRoute: InductionBankDetailsRoute,
  InductionSavingsPlanRoute: InductionSavingsPlanRoute,
  InductionServiceLevelRoute: InductionServiceLevelRoute,
  InductionWhatsAppDetailsRoute: InductionWhatsAppDetailsRoute,
  MobileIdRoute: MobileIdRoute,
  MortgageIdRoute: MortgageIdRoute,
  OnboardCreateAccountRoute: OnboardCreateAccountRoute,
  OnboardingBankDetailsRoute: OnboardingBankDetailsRoute,
  OnboardingServiceLevelRoute: OnboardingServiceLevelRoute,
  OnboardingWhatsAppDetailsRoute: OnboardingWhatsAppDetailsRoute,
  PreferencesUserIdRoute: PreferencesUserIdRoute,
  QuestsQuestIdRoute: QuestsQuestIdRoute,
  SessionIdRoute: SessionIdRoute,
  SettingsSplatRoute: SettingsSplatRoute,
  StatementIdRoute: StatementIdRoute,
  ToolsEnergyForecastRoute: ToolsEnergyForecastRoute,
  ToolsMobileHikesRoute: ToolsMobileHikesRoute,
  ToolsPayriseRoute: ToolsPayriseRoute,
  TypeformIdRoute: TypeformIdRoute,
  WidgetsEnergyForecastRoute: WidgetsEnergyForecastRoute,
  WidgetsMobileHikesRoute: WidgetsMobileHikesRoute,
  WidgetsPayriseRoute: WidgetsPayriseRoute,
  WizardBroadbandRoute: WizardBroadbandRoute,
  WizardEnergyRoute: WizardEnergyRoute,
  WizardMobileRoute: WizardMobileRoute,
  WizardMortgageRoute: WizardMortgageRoute,
  DocumentsIndexRoute: DocumentsIndexRoute,
  InductionIndexRoute: InductionIndexRoute,
  OnboardingIndexRoute: OnboardingIndexRoute,
  QuestsIndexRoute: QuestsIndexRoute,
  SavingsPlanIndexRoute: SavingsPlanIndexRoute,
  SettingsIndexRoute: SettingsIndexRoute,
  ToolsIndexRoute: ToolsIndexRoute,
  Budget2024AutumnRoute: Budget2024AutumnRoute,
  Budget2024SpringRoute: Budget2024SpringRoute,
  RecommendationBroadbandIdRoute: RecommendationBroadbandIdRoute,
  RecommendationEnergyIdRoute: RecommendationEnergyIdRoute,
  RecommendationMobileIdRoute: RecommendationMobileIdRoute,
  QuestsQuestIdTasksTaskIdRoute: QuestsQuestIdTasksTaskIdRoute,
  ToolsBudget2024AutumnRoute: ToolsBudget2024AutumnRoute,
  ToolsBudget2024SpringRoute: ToolsBudget2024SpringRoute,
  WidgetsBudget2024AutumnRoute: WidgetsBudget2024AutumnRoute,
  WidgetsBudget2024SpringRoute: WidgetsBudget2024SpringRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/car-insurance",
        "/connect-whatsapp",
        "/council-tax",
        "/debug",
        "/energy-forecast",
        "/forgot-password",
        "/gate",
        "/glow",
        "/home-insurance",
        "/login",
        "/manage-account",
        "/mobile-hikes",
        "/notifications",
        "/outlook-sso",
        "/payrise",
        "/referrals",
        "/reset-password",
        "/savings",
        "/sign-up",
        "/t",
        "/television",
        "/upload-document",
        "/waitlist",
        "/water",
        "/add-provider/$category",
        "/bank-details/$userId",
        "/broadband/$id",
        "/documents/$id",
        "/energy/$id",
        "/induction/bank-details",
        "/induction/savings-plan",
        "/induction/service-level",
        "/induction/whatsApp-details",
        "/mobile/$id",
        "/mortgage/$id",
        "/onboard/create-account",
        "/onboarding/bank-details",
        "/onboarding/service-level",
        "/onboarding/whatsApp-details",
        "/preferences/$userId",
        "/quests/$questId",
        "/session/$id",
        "/settings/$",
        "/statement/$id",
        "/tools/energy-forecast",
        "/tools/mobile-hikes",
        "/tools/payrise",
        "/typeform/$id",
        "/widgets/energy-forecast",
        "/widgets/mobile-hikes",
        "/widgets/payrise",
        "/wizard/broadband",
        "/wizard/energy",
        "/wizard/mobile",
        "/wizard/mortgage",
        "/documents/",
        "/induction/",
        "/onboarding/",
        "/quests/",
        "/savings-plan/",
        "/settings/",
        "/tools/",
        "/budget/2024/autumn",
        "/budget/2024/spring",
        "/recommendation/broadband/$id",
        "/recommendation/energy/$id",
        "/recommendation/mobile/$id",
        "/quests_/$questId/tasks/$taskId",
        "/tools/budget/2024/autumn",
        "/tools/budget/2024/spring",
        "/widgets/budget/2024/autumn",
        "/widgets/budget/2024/spring"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/car-insurance": {
      "filePath": "car-insurance.tsx"
    },
    "/connect-whatsapp": {
      "filePath": "connect-whatsapp.tsx"
    },
    "/council-tax": {
      "filePath": "council-tax.tsx"
    },
    "/debug": {
      "filePath": "debug.tsx"
    },
    "/energy-forecast": {
      "filePath": "energy-forecast.tsx"
    },
    "/forgot-password": {
      "filePath": "forgot-password.tsx"
    },
    "/gate": {
      "filePath": "gate.tsx"
    },
    "/glow": {
      "filePath": "glow.tsx"
    },
    "/home-insurance": {
      "filePath": "home-insurance.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/manage-account": {
      "filePath": "manage-account.tsx"
    },
    "/mobile-hikes": {
      "filePath": "mobile-hikes.tsx"
    },
    "/notifications": {
      "filePath": "notifications.tsx"
    },
    "/outlook-sso": {
      "filePath": "outlook-sso.tsx"
    },
    "/payrise": {
      "filePath": "payrise.tsx"
    },
    "/referrals": {
      "filePath": "referrals.tsx"
    },
    "/reset-password": {
      "filePath": "reset-password.tsx"
    },
    "/savings": {
      "filePath": "savings.tsx"
    },
    "/sign-up": {
      "filePath": "sign-up.tsx"
    },
    "/t": {
      "filePath": "t.tsx"
    },
    "/television": {
      "filePath": "television.tsx"
    },
    "/upload-document": {
      "filePath": "upload-document.tsx"
    },
    "/waitlist": {
      "filePath": "waitlist.tsx"
    },
    "/water": {
      "filePath": "water.tsx"
    },
    "/add-provider/$category": {
      "filePath": "add-provider.$category.tsx"
    },
    "/bank-details/$userId": {
      "filePath": "bank-details.$userId.tsx"
    },
    "/broadband/$id": {
      "filePath": "broadband.$id.tsx"
    },
    "/documents/$id": {
      "filePath": "documents.$id.tsx"
    },
    "/energy/$id": {
      "filePath": "energy.$id.tsx"
    },
    "/induction/bank-details": {
      "filePath": "induction.bank-details.tsx"
    },
    "/induction/savings-plan": {
      "filePath": "induction.savings-plan.tsx"
    },
    "/induction/service-level": {
      "filePath": "induction.service-level.tsx"
    },
    "/induction/whatsApp-details": {
      "filePath": "induction.whatsApp-details.tsx"
    },
    "/mobile/$id": {
      "filePath": "mobile.$id.tsx"
    },
    "/mortgage/$id": {
      "filePath": "mortgage.$id.tsx"
    },
    "/onboard/create-account": {
      "filePath": "onboard.create-account.tsx"
    },
    "/onboarding/bank-details": {
      "filePath": "onboarding.bank-details.tsx"
    },
    "/onboarding/service-level": {
      "filePath": "onboarding.service-level.tsx"
    },
    "/onboarding/whatsApp-details": {
      "filePath": "onboarding.whatsApp-details.tsx"
    },
    "/preferences/$userId": {
      "filePath": "preferences.$userId.tsx"
    },
    "/quests/$questId": {
      "filePath": "quests.$questId.tsx"
    },
    "/session/$id": {
      "filePath": "session.$id.tsx"
    },
    "/settings/$": {
      "filePath": "settings/$.tsx"
    },
    "/statement/$id": {
      "filePath": "statement.$id.tsx"
    },
    "/tools/energy-forecast": {
      "filePath": "tools.energy-forecast.tsx"
    },
    "/tools/mobile-hikes": {
      "filePath": "tools.mobile-hikes.tsx"
    },
    "/tools/payrise": {
      "filePath": "tools.payrise.tsx"
    },
    "/typeform/$id": {
      "filePath": "typeform.$id.tsx"
    },
    "/widgets/energy-forecast": {
      "filePath": "widgets.energy-forecast.tsx"
    },
    "/widgets/mobile-hikes": {
      "filePath": "widgets.mobile-hikes.tsx"
    },
    "/widgets/payrise": {
      "filePath": "widgets.payrise.tsx"
    },
    "/wizard/broadband": {
      "filePath": "wizard.broadband.tsx"
    },
    "/wizard/energy": {
      "filePath": "wizard.energy.tsx"
    },
    "/wizard/mobile": {
      "filePath": "wizard.mobile.tsx"
    },
    "/wizard/mortgage": {
      "filePath": "wizard.mortgage.tsx"
    },
    "/documents/": {
      "filePath": "documents.index.tsx"
    },
    "/induction/": {
      "filePath": "induction.index.tsx"
    },
    "/onboarding/": {
      "filePath": "onboarding.index.tsx"
    },
    "/quests/": {
      "filePath": "quests.index.tsx"
    },
    "/savings-plan/": {
      "filePath": "savings-plan.index.tsx"
    },
    "/settings/": {
      "filePath": "settings/index.tsx"
    },
    "/tools/": {
      "filePath": "tools.index.tsx"
    },
    "/budget/2024/autumn": {
      "filePath": "budget.2024.autumn.tsx"
    },
    "/budget/2024/spring": {
      "filePath": "budget.2024.spring.tsx"
    },
    "/recommendation/broadband/$id": {
      "filePath": "recommendation.broadband.$id.tsx"
    },
    "/recommendation/energy/$id": {
      "filePath": "recommendation.energy.$id.tsx"
    },
    "/recommendation/mobile/$id": {
      "filePath": "recommendation.mobile.$id.tsx"
    },
    "/quests_/$questId/tasks/$taskId": {
      "filePath": "quests_.$questId.tasks.$taskId.tsx"
    },
    "/tools/budget/2024/autumn": {
      "filePath": "tools.budget.2024.autumn.tsx"
    },
    "/tools/budget/2024/spring": {
      "filePath": "tools.budget.2024.spring.tsx"
    },
    "/widgets/budget/2024/autumn": {
      "filePath": "widgets.budget.2024.autumn.tsx"
    },
    "/widgets/budget/2024/spring": {
      "filePath": "widgets.budget.2024.spring.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
