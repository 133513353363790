import { defineMessage, MessageDescriptor, useIntl } from "react-intl"

import {
  GlowButton,
  GlowFlexbox,
  GlowIcon,
  GlowIconName,
  GlowText,
} from "src/glow"
import useShare from "src/hooks/useShare"

function buildButtonContent({
  shareMethod,
  shared,
}: {
  shareMethod: "share" | "clipboard"
  shared: boolean
}): { icon: GlowIconName; label: MessageDescriptor } {
  if (shareMethod === "share") {
    return shared
      ? {
          icon: "check_circle_1_bold",
          label: defineMessage({
            id: "component.shareLink.button.shared",
            defaultMessage: "Shared",
          }),
        }
      : {
          icon: "send_email_regular",
          label: defineMessage({
            id: "component.shareLink.button.share",
            defaultMessage: "Share",
          }),
        }
  }

  return shared
    ? {
        icon: "check_circle_1_bold",
        label: defineMessage({
          id: "component.shareLink.button.copied",
          defaultMessage: "Link copied",
        }),
      }
    : {
        icon: "copy_1_regular",
        label: defineMessage({
          id: "component.shareLink.button.copy",
          defaultMessage: "Copy Link",
        }),
      }
}

type ShareLinkProps = {
  isLoading?: boolean
  linkToShare: string
  loadingText?: string
  nativeShareText: string
  nativeShareTitle: string
  onShared?: () => void
  title: string
  disclaimer?: string
}

export default function ShareLink(props: ShareLinkProps) {
  const intl = useIntl()
  const share = useShare()

  const handleShareLink = async () => {
    await share.share({
      url: props.linkToShare,
      title: props.nativeShareTitle,
      ...(share.method === "share"
        ? {
            text: props.nativeShareText,
          }
        : { text: "" }),
    })

    props.onShared?.()

    await new Promise((resolve) => setTimeout(resolve, 3_000))

    share.setShareState((state) => ({
      ...state,
      isShared: false,
    }))
  }

  return (
    <GlowFlexbox direction="column" gap="4" className="w-full">
      <GlowText as="h3" fontWeight="bold">
        {props.title}
      </GlowText>
      <GlowFlexbox direction="column" gap="3">
        <GlowFlexbox gap="2">
          <GlowFlexbox
            justifyContent="start"
            alignItems="center"
            className="w-[60%] overflow-y-scroll text-nowrap rounded-lg bg-gray-100 p-2 pl-3"
          >
            {props.isLoading ? (
              <GlowFlexbox gap="1" justifyContent="center" alignItems="center">
                <GlowIcon
                  name="loading_regular"
                  className="text-off-black-64 h-4 w-4 animate-[spin_3s_linear_infinite]"
                />
                <GlowText as="p" size="sm" className="text-off-black-64">
                  {props.loadingText}
                </GlowText>
              </GlowFlexbox>
            ) : (
              <GlowText as="p" size="sm" className="text-off-black-64">
                {props.linkToShare}
              </GlowText>
            )}
          </GlowFlexbox>

          <GlowButton
            variant="primary"
            iconName={
              buildButtonContent({
                shareMethod: share.method,
                shared: share.isShared,
              }).icon
            }
            iconClassName={
              share.isShared ? " text-virtuous-green-400" : undefined
            }
            isDisabled={props.isLoading || share.isSharing}
            onClick={handleShareLink}
            label={intl.formatMessage(
              buildButtonContent({
                shareMethod: share.method,
                shared: share.isShared,
              }).label,
            )}
            className="w-[40%] shrink-0"
          />
        </GlowFlexbox>

        {props.disclaimer && (
          <GlowText as="p" size="sm" className="text-off-black-64">
            {props.disclaimer}
          </GlowText>
        )}
      </GlowFlexbox>
    </GlowFlexbox>
  )
}
