import { useIntl } from "react-intl"

import { DesktopNav } from "src/components/Nav/DesktopNav"
import { MobileTopNav } from "src/components/Nav/MobileTopNav"
import { GlowLayoutCentered } from "src/glow/GlowLayout"
import { GlowPageLayout } from "src/glow/GlowPageLayout"
import LoadingPagePlaceholder from "src/LoadingPagePlaceholder"

type SavingsPageLayoutProps = {
  children: React.ReactNode
  newDesign?: boolean
}

export default function SavingsPageLayout({
  children,
  newDesign,
}: SavingsPageLayoutProps) {
  const intl = useIntl()
  return (
    <div className="min-h-full bg-gray-100">
      <GlowPageLayout
        mobileNav={{
          top: (
            <MobileTopNav
              variant="secondary"
              title={intl.formatMessage({
                id: "savingsPage.mobileNav.title",
                defaultMessage: "Savings",
              })}
            />
          ),
        }}
        desktopNav={<DesktopNav />}
      >
        {newDesign ? (
          <>{children ?? <LoadingPagePlaceholder />}</>
        ) : (
          <GlowLayoutCentered
            variant="wide"
            className="relative mx-4 md:mx-0 md:mt-8"
          >
            {children ?? <LoadingPagePlaceholder />}
          </GlowLayoutCentered>
        )}
      </GlowPageLayout>
    </div>
  )
}
