import { useEffect } from "react"

import { useNavigate } from "@tanstack/react-router"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { useRedirectToWaitlist_household$key } from "./__generated__/useRedirectToWaitlist_household.graphql"

type useRedirectToWaitlistProps = {
  household: useRedirectToWaitlist_household$key | null
}

export default function useRedirectToWaitlist({
  household: householdKey,
}: useRedirectToWaitlistProps) {
  const navigate = useNavigate()
  const household = useFragment(
    graphql`
      fragment useRedirectToWaitlist_household on Household {
        inWaitlist
        serviceLevel
        isDelegated
      }
    `,
    householdKey,
  )

  useEffect(() => {
    if (household && household.inWaitlist) {
      household.isDelegated || household.serviceLevel === "DO_EVERYTHING_MYSELF"
        ? navigate({ to: "/waitlist", replace: true })
        : navigate({ to: "/sign-up", replace: true })
    }
  }, [navigate, household])
}
