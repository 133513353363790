/**
 * @generated SignedSource<<ca40aa2b374bf067ff57e30af6685188>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NousServiceLevel = "DO_ALL_OF_IT_FOR_ME" | "DO_EVERYTHING_MYSELF" | "DO_MOST_OF_IT_FOR_ME" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useRedirectToWaitlist_household$data = {
  readonly inWaitlist: boolean | null | undefined;
  readonly isDelegated: boolean | null | undefined;
  readonly serviceLevel: NousServiceLevel | null | undefined;
  readonly " $fragmentType": "useRedirectToWaitlist_household";
};
export type useRedirectToWaitlist_household$key = {
  readonly " $data"?: useRedirectToWaitlist_household$data;
  readonly " $fragmentSpreads": FragmentRefs<"useRedirectToWaitlist_household">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useRedirectToWaitlist_household",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inWaitlist",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDelegated",
      "storageKey": null
    }
  ],
  "type": "Household",
  "abstractKey": null
};

(node as any).hash = "371d07e79c9fa37147f62b490b93b0be";

export default node;
