/**
 * @generated SignedSource<<de26853596ec63465c3c87606b6ddc93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettingsPageOverview_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DeleteMyAccount_user">;
  readonly " $fragmentType": "SettingsPageOverview_user";
};
export type SettingsPageOverview_user$key = {
  readonly " $data"?: SettingsPageOverview_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettingsPageOverview_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsPageOverview_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteMyAccount_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "4501864257bc9ffd362722ce885e3268";

export default node;
