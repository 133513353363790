import React from "react"

import { datadogRum } from "@datadog/browser-rum"
import { RelayEnvironmentProvider } from "react-relay"
import {
  Environment,
  Network,
  RecordSource,
  RelayFeatureFlags,
  Store,
} from "relay-runtime"

import { getAccessToken, useUser } from "../auth"
import { getStableId } from "../Gate"
import useEmulateUser from "../hooks/useEmulateUser"
import { logger } from "../logger"

RelayFeatureFlags.ENABLE_RELAY_RESOLVERS = true

type Props = {
  children: React.ReactNode
}

function trackRelayResponseTimeMetric(
  operationName: string,
  durationMs: number,
) {
  const rumContext = datadogRum.getInternalContext?.()
  const traceId = rumContext?.view?.referrer

  const sanitizedOperationName = operationName || "UnknownOperation"
  const message = `${sanitizedOperationName} took ${durationMs}ms`
  const messageContext = {
    durationMs,
    operationName: sanitizedOperationName,
    traceId,
  }

  datadogRum.addTiming(`${sanitizedOperationName}_duration`)
  datadogRum.addAction("Relay Operation Response Time", {
    message,
    customAttributes: messageContext,
  })

  logger.info("Relay Operation Response Time", messageContext)
}

function buildRelayEnvironment(
  userId: string | null,
  emulatingUserId: string | null,
) {
  const source = new RecordSource()
  const store = new Store(source)
  logger.debug("Creating environment for user id", { userId, emulatingUserId })
  const network = Network.create(
    async (operation, variables, _cache, uploadables) => {
      if (operation.text == null) {
        throw new Error("Unsupported")
      }
      const token = await getAccessToken()
      const authHeaders: HeadersInit = token
        ? {
            authorization: `Bearer ${token.token}`,
            "auth-provider": token.provider,
          }
        : {}

      const headers: HeadersInit = {
        ...authHeaders,
        "x-stable-id": getStableId(),
        ...(emulatingUserId && { "x-on-behalf-of": emulatingUserId }),
      }

      const request: RequestInit = {
        method: "POST",
      }

      if (uploadables) {
        if (!window.FormData) {
          throw new Error("Uploading files without `FormData` not supported.")
        }

        const formData = new FormData()
        formData.append("query", operation.text)
        formData.append("variables", JSON.stringify(variables))

        Object.keys(uploadables).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
            formData.append(key, uploadables[key])
          }
        })

        const res = new Response(formData)
        const blob = await res.blob()

        // can't use own blob's type since spec lowercase the blob.type
        // so we get the actual content type
        const type = res.headers.get("content-type")
        const size = blob.size

        request.body = formData

        if (type) {
          headers["Content-Type"] = type
        }
        headers["Content-Length"] = size.toString()
      } else {
        headers["Content-Type"] = "application/json"
        request.body = JSON.stringify({
          query: operation.text,
          variables,
        })
      }

      request.headers = headers

      const startTime = Date.now()
      try {
        logger.debug("Fetching", { name: operation.name })
        const result = await fetch(`${__API_URL__}/graphql`, request)
        const parsedResult = await result.json()
        logger.debug("Parsed result", { parsedResult })
        return parsedResult
      } finally {
        const durationMs = Date.now() - startTime
        trackRelayResponseTimeMetric(operation.name, durationMs)
      }
    },
  )
  const handlerProvider = null

  return new Environment({
    handlerProvider,
    network,
    store,
  })
}

let environment: Environment | null = null
let lastUserId: string | null = null
let lastEmulatingUserId: string | null = null

export function RelayProvider(props: Props) {
  const userId = useUser()?.id ?? null
  const emulatingUserId = useEmulateUser().emulatingUserId ?? null
  if (
    userId !== lastUserId ||
    emulatingUserId !== lastEmulatingUserId ||
    environment == null
  ) {
    environment = buildRelayEnvironment(userId, emulatingUserId)
    lastUserId = userId
    lastEmulatingUserId = emulatingUserId
  }
  return (
    <RelayEnvironmentProvider environment={environment}>
      {props.children}
    </RelayEnvironmentProvider>
  )
}
